// package: service
// file: apigateway/service/folder.proto

import * as apigateway_service_folder_pb from "../../apigateway/service/folder_pb";
export class FolderService {
  static serviceName = "service.FolderService";
}
export namespace FolderService {
  export class AddFolder {
    static readonly methodName = "AddFolder";
    static readonly service = FolderService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_service_folder_pb.AddFolderRequest;
    static readonly responseType = apigateway_service_folder_pb.AddFolderReply;
  }
  export class GetMyFolders {
    static readonly methodName = "GetMyFolders";
    static readonly service = FolderService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_service_folder_pb.GetMyFoldersRequest;
    static readonly responseType = apigateway_service_folder_pb.GetMyFoldersReply;
  }
  export class UpdateFolder {
    static readonly methodName = "UpdateFolder";
    static readonly service = FolderService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_service_folder_pb.UpdateFolderRequest;
    static readonly responseType = apigateway_service_folder_pb.UpdateFolderReply;
  }
  export class DeleteFolder {
    static readonly methodName = "DeleteFolder";
    static readonly service = FolderService;
    static readonly requestStream = false;
    static readonly responseStream = false;
    static readonly requestType = apigateway_service_folder_pb.DeleteFolderRequest;
    static readonly responseType = apigateway_service_folder_pb.DeleteFolderReply;
  }
}
