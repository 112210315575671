<template>
  <div class="c-PurchaseSp">
    <div
      class="c-purchaseSpStop"
      v-if="!isLoggedIn && serviceDetail.serviceDetail.purchaseStatus === 'stop'"
    >
      <c-button-v2
        color="primary"
        size="m"
        width="100%"
        disabled
      >
        <coconala-icon name="slash-circle-o" />
        {{ $t('components.contents_purchaseSP.reception_suspension') }}（
        <span v-if="serviceDetail.serviceDetail.unitTime">
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >円 / {{ serviceDetail.serviceDetail.unitTime }}分
        </span>
        <span v-else>
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >{{ $t('yen') }}
        </span>
        ）
      </c-button-v2>
    </div>
    <div
      class="c-purchaseSpFullCorrespondence"
      v-else-if="!isLoggedIn && serviceDetail.serviceDetail.fullCorrespondenceFlag"
    >
      <c-button-v2
        color="primary"
        size="m"
        width="100%"
        disabled
      >
        <coconala-icon name="slash-circle-o" />
        {{ $t('components.contents_purchaseSP.full') }}（
        <span v-if="serviceDetail.serviceDetail.unitTime">
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >円 / {{ serviceDetail.serviceDetail.unitTime }}分
        </span>
        <span v-else>
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >{{ $t('yen') }}
        </span>
        ）
      </c-button-v2>
    </div>
    <div
      class="c-purchaseSpArchive"
      v-else-if="!isLoggedIn && serviceDetail.serviceDetail.purchaseStatus === 'archive'"
    >
      <c-button-v2
        color="primary"
        size="m"
        width="100%"
        disabled
      >
        <coconala-icon name="slash-circle-o" />
        {{ $t('components.contents_purchaseSP.closed') }}
      </c-button-v2>
    </div>
    <div
      class="c-purchaseSpWaitingOnly"
      v-else-if="!isLoggedIn && serviceDetail.serviceDetail.purchaseStatus === 'waiting_only'"
    >
      <c-button-v2
        color="primary"
        size="m"
        width="100%"
        disabled
      >
        <coconala-icon name="slash-circle-o" />
        待機中のみ受付可
      </c-button-v2>
    </div>
    <div
      class="c-purchaseSpWaiting"
      v-else-if="!isLoggedIn && serviceDetail.serviceDetail.purchaseStatus === 'waiting'"
    >
      <c-button-v2
        color="primary-blue"
        size="m"
        width="100%"
        need-logged-in
      >
        <coconala-icon name="phone" />
        すぐに電話する（
        <span>
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >円/分
        </span>
        ）
      </c-button-v2>
    </div>
    <div
      class="c-purchaseSpWaitingReservable"
      v-else-if="
        !isLoggedIn &&
        serviceDetail.serviceDetail.serviceClass === serviceClass.phone &&
        ['waiting_reservable', 'waiting_and_can_advance_reservation'].includes(
          serviceDetail.serviceDetail.purchaseStatus
        )
      "
    >
      <c-button-v2
        color="primary-blue"
        size="m"
        width="100%"
        need-logged-in
      >
        <coconala-icon name="phone" />
        すぐに電話する（
        <span>
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >円/分
        </span>
        ）
      </c-button-v2>
      <c-button-v2
        color="primary"
        size="m"
        width="100%"
        need-logged-in
        class="c-purchaseSpWaitingReservable_reservable"
      >
        <coconala-icon name="calender" />
        電話予約を申込む
      </c-button-v2>
    </div>
    <div
      class="c-purchaseSpReservableOnly"
      v-else-if="
        !isLoggedIn &&
        serviceDetail.serviceDetail.serviceClass === serviceClass.phone &&
        ['reservable_only', 'can_advance_reservation'].includes(
          serviceDetail.serviceDetail.purchaseStatus
        )
      "
    >
      <c-button-v2
        color="primary"
        size="m"
        width="100%"
        need-logged-in
      >
        <coconala-icon name="calender" />
        電話予約を申込む（
        <span>
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >円/分
        </span>
        ）
      </c-button-v2>
    </div>
    <div
      class="c-purchaseSpOther"
      v-else-if="!isLoggedIn"
    >
      <c-button-v2
        color="primary"
        size="m"
        width="100%"
        need-logged-in
      >
        <coconala-icon name="cart" />
        {{ $t('components.contents_purchaseSP.purchase_screen') }}（
        <span v-if="serviceDetail.serviceDetail.unitTime">
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >{{ $t('yen') }} /
          {{
            serviceDetail.serviceDetail.serviceClass === serviceClass.phone
              ? '1'
              : serviceDetail.serviceDetail.unitTime
          }}{{ $t('minute') }}
        </span>
        <span v-else>
          <span class="c-price_price">{{ $util.numberWithDelimiter(totalPrice) }}</span
          >{{ $t('yen') }}
        </span>
        ）
      </c-button-v2>
    </div>
  </div>
</template>
<script>
// vuex
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ContentsPurchaseSpPurchaseButton',
  props: {
    serviceDetail: {
      type: Object,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('constants', ['serviceClass'])
  }
}
</script>
<style lang="scss" scoped>
@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-purchaseSpStop,
  .c-purchaseSpArchive,
  .c-purchaseSpWaitingOnly,
  .c-purchaseSpWaiting,
  .c-purchaseSpReservableOnly,
  .c-purchaseSpWaitingReservable,
  .c-purchaseSpFullCorrespondence,
  .c-purchaseSpOther {
    margin-bottom: 12px;
  }

  .c-purchaseSpWaitingReservable {
    &_reservable {
      margin-top: 8px;
    }
  }
}
</style>
