<template>
  <div class="c-serviceDetailFooter">
    <div
      class="c-otherService"
      v-if="providerServices.servicesList.length"
    >
      <ClientOnly>
        <ProviderServices
          :provider-services="providerServices"
          :is-loading-provider-services="isLoadingProviderServices"
          v-if="providerServices.servicesList.length"
          @mountedProviderServices="onMountedProviderServices"
        />
      </ClientOnly>
    </div>

    <div
      class="c-recommendRuleBase"
      ref="recommendRuleBase"
      v-if="recommendRuleBase.recommendServicesList.length"
      v-intersect-on.once="{ threshold: 1 }"
      @intersect="callRecommendImpression(recommendRuleBase.impressionParams)"
    >
      <ClientOnly>
        <Recommend
          :recommend="recommendRuleBase"
          :recommend-name="`${recommendCategoryTitle}の関連サービス`"
          :is-loading-recommend="isLoadingRecommendRuleBase"
          :url-refs="recommendRuleBaseRefs()"
          v-if="recommendRuleBase.recommendServicesList.length"
          @mountedRecommend="onMountedRecommendRuleBase"
        />
      </ClientOnly>
    </div>

    <div
      class="c-faq js-contentsMenu-faq"
      id="serviceContentsFaq"
      v-if="serviceDetail.serviceDetail.faqsList.length"
    >
      <HeadingSecond
        :heading="$t('components.provider_service.questions')"
        has-border
      />
      <ContentsFaq :service-faq="serviceDetail.serviceDetail.faqsList" />
    </div>

    <div
      class="c-example js-contentsMenu-example"
      id="serviceContentsExample"
      v-if="serviceDetail.serviceDetail.sampleMessagesList.length"
    >
      <HeadingSecond
        heading="トークルーム回答例"
        has-border
      />
      <ContentsExample :service-sample-message="serviceDetail.serviceDetail.sampleMessagesList" />
    </div>

    <div
      class="c-recommendViewBase"
      ref="recommendViewBase"
      v-if="recommendViewBase.recommendServicesList.length"
      v-intersect-on.once="{ threshold: 1 }"
      @intersect="callRecommendImpression(recommendViewBase.impressionParams)"
    >
      <ClientOnly>
        <Recommend
          :recommend="recommendViewBase"
          recommend-name="このサービスを閲覧している人はこのサービスもチェックしています"
          :is-loading-recommend="isLoadingRecommendViewBase"
          :url-refs="recommendViewBaseRefs()"
          v-if="recommendViewBase.recommendServicesList.length"
          @mountedRecommend="onMountedRecommendViewBase"
        />
      </ClientOnly>
    </div>

    <div
      class="c-rating js-contentsMenu-rating"
      id="serviceContentsRating"
      v-if="serviceOverallRatingCount"
    >
      <HeadingSecond
        :heading="ratingHeading"
        has-border
      />
      <ContentsRating
        :service-detail-ratings="serviceDetailRatings"
        :rating-indicators="ratingIndicators"
        :is-my-service="isMyService"
      />
    </div>

    <div
      class="c-phoneServicePc"
      v-if="isPC && serviceDetail.serviceDetail.serviceClass === serviceClass.phone"
    >
      <HeadingSecond
        heading="電話相談申し込みの流れ"
        has-border
      />
      <div class="c-phoneServicePc_image">
        <img
          src="@/assets/images/pages/services/phone-service-pc.png"
          alt="電話相談申し込みの流れ"
          width="598"
          height="231"
          loading="lazy"
          decoding="async"
        />
      </div>
      <div class="c-phoneServicePc_link">
        <a
          href="/pages/guide_phone"
          target="_blank"
        >
          電話相談サービスについて
          <CoconalaIcon name="chevron-right" />
        </a>
      </div>
    </div>

    <div
      class="c-share"
      v-if="isSP || isTB"
    >
      <ClientOnly>
        <ShareLinks
          class="c-share_social"
          :is-monochromatic="false"
          :title="metaTitle"
          :path="$route.path"
        />
      </ClientOnly>
      <a
        v-if="isLoggedIn && !isMyService"
        class="c-violation"
        @click="$emit('violation')"
      >
        {{ $t('components.service_violation.message') }}
      </a>
    </div>

    <div
      v-if="
        isJapanese &&
        (isSP || isTB) &&
        serviceDetail.serviceDetail.serviceClass === serviceClass.text
      "
      class="c-serviceSpFooter"
    >
      <div class="c-serviceSpFooter_guide">
        <ul>
          <li>
            <a
              href="/pages/guide_top"
              class="c-serviceSpFooterListItem"
            >
              <div
                class="c-serviceSpFooterListItem_columnLeft c-serviceSpFooterListItem_columnLeft-guide"
              >
                <img
                  src="@/assets/images/pages/services/guide-coconala.png"
                  alt="ご利用ガイド"
                  width="58"
                  height="58"
                  loading="lazy"
                  decoding="async"
                />
              </div>
              <div
                class="c-serviceSpFooterListItem_columnMiddle c-serviceSpFooterListItem_columnMiddle-guide"
              >
                <div class="c-serviceSpFooterListItem_guideCaption">ご利用ガイド</div>
                <div class="c-serviceSpFooterListItem_guideDescription">
                  サービスの出品、購入、取引の流れ、お支払い方法・手数料や、ココナラを安心・安全に使って頂くための制度やマナーなど、ココナラの使い方はこちら。
                </div>
              </div>
              <div class="c-serviceSpFooterListItem_columnRight">
                <CoconalaIcon
                  name="chevron-right"
                  size="12px"
                />
              </div>
            </a>
          </li>
        </ul>
      </div>
      <HeadingSecond heading="お支払い方法" />
      <div class="c-serviceSpFooter_images">
        <div class="c-serviceSpFooter_imageMethod">
          <img
            src="@/assets/images/pages/services/payment-methods.png"
            alt="お支払い方法"
            width="236"
            height="137"
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
    </div>

    <div
      class="c-serviceSpFooter"
      v-if="(isSP || isTB) && serviceDetail.serviceDetail.serviceClass === serviceClass.phone"
    >
      <div class="c-serviceSpFooter_qa">
        <ul>
          <li>
            <a
              :href="
                $coconala.helpURL(
                  '/search?query=%E9%9B%BB%E8%A9%B1%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9'
                )
              "
              class="c-serviceSpFooterListItem"
            >
              <div class="c-serviceSpFooterListItem_columnLeft">電話相談サービスQ&A</div>
              <div class="c-serviceSpFooterListItem_columnRight">
                <CoconalaIcon
                  name="chevron-right"
                  size="12px"
                />
              </div>
            </a>
          </li>
        </ul>
      </div>
      <HeadingSecond heading="お支払い方法" />
      <div class="c-serviceSpFooter_images">
        <div class="c-serviceSpFooter_imageMethod">
          <img
            src="@/assets/images/pages/services/phone-payment-method.png"
            alt="お支払い方法"
            width="208"
            height="34"
            loading="lazy"
            decoding="async"
          />
        </div>
        <div
          class="c-serviceSpFooter_imageCoin"
          v-if="!isFromCoconalaApp"
        >
          <a href="/smartphone/points?ref=phone_service">
            <img
              src="@/assets/images/common/banner/coconala_coin_rectangle.png"
              alt="ココナラコインの購入はこちら"
              width="288"
              height="171"
              loading="lazy"
              decoding="async"
            />
          </a>
        </div>
      </div>
    </div>

    <div
      v-if="(isSP || isTB) && isJapanese"
      class="c-serviceSpFooter_help"
    >
      <ul>
        <li>
          <a
            :href="$coconala.helpURL('/sections/360001538593')"
            class="c-serviceSpFooterListItem"
          >
            <div class="c-serviceSpFooterListItem_columnLeft">お支払い方法について</div>
            <div class="c-serviceSpFooterListItem_columnRight">
              <CoconalaIcon
                name="chevron-right"
                size="12px"
              />
            </div>
          </a>
        </li>
        <li>
          <a
            :href="$coconala.helpURL('/sections/360001514014')"
            class="c-serviceSpFooterListItem"
          >
            <div class="c-serviceSpFooterListItem_columnLeft">キャンセルについて</div>
            <div class="c-serviceSpFooterListItem_columnRight">
              <CoconalaIcon
                name="chevron-right"
                size="12px"
              />
            </div>
          </a>
        </li>
        <li v-if="this.serviceDetail.serviceDetail.serviceClass === serviceClass.phone">
          <a
            :href="
              $coconala.helpURL(
                '/search?query=%E9%9B%BB%E8%A9%B1%E3%82%B5%E3%83%BC%E3%83%93%E3%82%B9%20%E6%96%99%E9%87%91'
              )
            "
            class="c-serviceSpFooterListItem"
          >
            <div class="c-serviceSpFooterListItem_columnLeft">料金システムについて</div>
            <div class="c-serviceSpFooterListItem_columnRight">
              <CoconalaIcon
                name="chevron-right"
                size="12px"
              />
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
const RECOMMEND_IMP_ACTION = 'view_service_recommend'
const BUSINESS_RECOMMEND_IMP_ACTION = 'view_business_service_recommend'
const RECOMMEND_VIEW_REF = 'service_recommend'
const BUSINESS_RECOMMEND_VIEW_REF = 'business_service_recommend'
const RECOMMEND_ALGORITHM_RULEBASE = 'rule_base'
const RECOMMEND_ALGORITHM_VIEWBASE = 'view_base'

import HeadingSecond from './components/HeadingSecond'
import ShareLinks from '~/components/organisms/ShareLinks'

// vuex
import { mapGetters, mapState } from 'vuex'
import ContentsRating from './components/ContentsRating'

export default {
  name: 'ServiceDetailFooter',
  components: {
    HeadingSecond,
    ShareLinks,
    ContentsExample: () => import('./components/ContentsExample'),
    ContentsFaq: () => import('./components/ContentsFaq'),
    ContentsRating,
    ProviderServices: () => import('./components/ProviderServices'),
    Recommend: () => import('./components/Recommend')
  },
  inject: {
    $serviceOverallRatingCountReactive: { default: () => 0 }
  },
  props: {
    position: {
      type: Number
    },
    scrollToOffset: {
      type: Number
    },
    serviceDetail: {
      type: Object,
      required: true
    },
    serviceDetailRatings: {
      type: Object,
      required: true
    },
    ratingIndicators: {
      type: Object,
      required: true
    },
    isMyService: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    metaTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoadingProviderServices: true,
      isLoadingRecommendRuleBase: true,
      isLoadingRecommendViewBase: true,
      isInitializedProviderServices: false,
      isInitializedRecommendRuleBase: false,
      isInitializedRecommendViewBase: false,
      providerServices: {
        providerStatus: {},
        servicesList: []
      },
      recommendRuleBase: {
        algorithm: '',
        impressionParams: '',
        recommendServicesList: []
      },
      recommendViewBase: {
        algorithm: '',
        impressionParams: '',
        recommendServicesList: []
      },
      isJapanese: !this.$translate.isTranslatableTarget()
    }
  },
  computed: {
    ...mapState('constants', ['serviceClass']),
    ...mapState('auth/user', ['isBusiness']),
    ...mapGetters('ui', ['isPC', 'isTB', 'isSP']),
    ...mapGetters('auth', ['isFromCoconalaApp', 'isLoggedIn']),
    ...mapState('my/header', ['privateAccountInfo']),
    recommendCategoryTitle() {
      let categoryName = this.serviceDetail.serviceDetail.masterParentCategoryName
        ? this.serviceDetail.serviceDetail.masterParentCategoryName
        : this.serviceDetail.serviceDetail.parentCategoryName
      if (this.serviceDetail.serviceDetail.masterChildCategoryName) {
        categoryName = this.serviceDetail.serviceDetail.masterChildCategoryName
      } else if (this.serviceDetail.serviceDetail.childCategoryName) {
        categoryName = this.serviceDetail.serviceDetail.childCategoryName
      }
      return categoryName
    },
    isInitializedRelatedServices() {
      return (
        this.isInitializedProviderServices &&
        this.isInitializedRecommendRuleBase &&
        this.isInitializedRecommendViewBase
      )
    },
    ratingHeading() {
      return this.isPC
        ? `${this.$t(
            'components.contents_rating.evaluation_etc'
          )}（${this.$util.numberWithDelimiter(this.serviceOverallRatingCount)}${this.$t(
            'cases'
          )}）`
        : `${this.$t('components.contents_rating.evaluation_etc')}`
    },
    serviceOverallRatingCount() {
      return this.$serviceOverallRatingCountReactive()
    }
  },
  watch: {
    // NOTE: レイアウトシフトを起こしうるコンポーネントの初期化終了後にスクロールさせたいので、初期化状態を監視する
    isInitializedRelatedServices: function (newValue) {
      if (
        newValue &&
        $nuxt.$route.hash === '#serviceContentsRating' &&
        this.serviceOverallRatingCount
      ) {
        this.$nextTick(() => {
          this.$scrollTo($nuxt.$route.hash, 0, { offset: this.scrollToOffset })
        })
      }
    }
  },
  mounted() {
    this.getProviderServices()
    this.getRuleBaseRecommend(RECOMMEND_ALGORITHM_RULEBASE)
    this.getViewBaseRecommend(RECOMMEND_ALGORITHM_VIEWBASE)
  },
  methods: {
    async getProviderServices() {
      const userId = this.serviceDetail.serviceDetail.providerId
      const serviceId = this.serviceDetail.serviceDetail.id
      const providerServices = await this.$store.dispatch(
        'pages/services/serviceDetail/providerServicesRequests',
        {
          userId,
          serviceId
        }
      )
      if (this.$util.hasPath(providerServices, 'servicesList')) {
        this.providerServices = providerServices
      }
      this.isLoadingProviderServices = false
      if (!this.providerServices?.servicesList?.length) {
        this.isInitializedProviderServices = true
      }
    },
    async getRuleBaseRecommend(algorithm) {
      const userId = this.serviceDetail.serviceDetail.providerId
      const businessFlag = !!this.privateAccountInfo.isMzhc
      const recommendRuleBase = await this.$store.dispatch(
        'pages/services/serviceDetail/recommendRequests',
        {
          serviceId: this.serviceDetail.serviceDetail.id,
          algorithm: algorithm,
          businessFlag: businessFlag
        }
      )
      this.recommendRuleBase = recommendRuleBase
      this.isLoadingRecommendRuleBase = false
      if (!this.recommendRuleBase?.recommendServicesList?.length) {
        this.isInitializedRecommendRuleBase = true
      }
    },
    async getViewBaseRecommend(algorithm) {
      const userId = this.serviceDetail.serviceDetail.providerId
      const businessFlag = !!this.privateAccountInfo.isMzhc
      const recommendViewBase = this.$store.dispatch(
        'pages/services/serviceDetail/recommendRequests',
        {
          serviceId: this.serviceDetail.serviceDetail.id,
          algorithm: algorithm,
          businessFlag: businessFlag
        }
      )
      this.recommendViewBase = await recommendViewBase
      this.isLoadingRecommendViewBase = false
      if (!this.recommendViewBase?.recommendServicesList?.length) {
        this.isInitializedRecommendViewBase = true
      }
    },
    /**
     * レコメンド表示計測
     * @param {string} detailJson JSON文字列
     */
    async callRecommendImpression(detailJson) {
      if (!this.isBusiness) {
        try {
          const parsedDetailJson = JSON.parse(detailJson)
          parsedDetailJson['selected_lang_code'] = this.$translate.getLanguageCode()
          detailJson = JSON.stringify(parsedDetailJson)
        } catch {
          // 失敗しても何もしない
        }
      }
      const requestPrams = {
        action: this.isBusiness ? BUSINESS_RECOMMEND_IMP_ACTION : RECOMMEND_IMP_ACTION,
        detailJson
      }
      try {
        await this.$store.dispatch('big_query/log_action/addLogUserAction', requestPrams)
      } catch (e) {
        this.$sentry.captureException(e)
      }
    },
    recommendRuleBaseRefs() {
      return {
        ref: this.isBusiness ? BUSINESS_RECOMMEND_VIEW_REF : RECOMMEND_VIEW_REF,
        ref_item_kind: 'service',
        ref_item: this.serviceDetail.serviceDetail.id,
        ref_algorithm: RECOMMEND_ALGORITHM_RULEBASE
      }
    },
    recommendViewBaseRefs() {
      return {
        ref: this.isBusiness ? BUSINESS_RECOMMEND_VIEW_REF : RECOMMEND_VIEW_REF,
        ref_item_kind: 'service',
        ref_item: this.serviceDetail.serviceDetail.id,
        ref_algorithm: RECOMMEND_ALGORITHM_VIEWBASE
      }
    },
    onMountedProviderServices() {
      this.isInitializedProviderServices = true
    },
    onMountedRecommendRuleBase() {
      this.isInitializedRecommendRuleBase = true
    },
    onMountedRecommendViewBase() {
      this.isInitializedRecommendViewBase = true
    }
  }
}
</script>
<style lang="scss" scoped>
.c-otherService {
  margin-bottom: 60px;
}

.c-recommendViewBase {
  margin-bottom: 60px;
}

.c-recommendRuleBase {
  margin-bottom: 60px;
}

.c-faq {
  display: inline-block;
  margin-bottom: 60px;
  width: 690px;
}

.c-example {
  display: inline-block;
  margin-bottom: 60px;
  width: 690px;
}

.c-rating,
.c-phoneServicePc {
  display: inline-block;
  width: 884px;
}

.c-rating {
  margin-bottom: 60px;
}

.c-phoneServicePc {
  &_image {
    padding-top: 4px;
  }

  &_link {
    margin-top: 16px;
    text-align: right;
  }
}

@media (min-width: breakpoint(ServiceDetail, S)) and (max-width: breakpoint(ServiceDetail, M)) {
  .c-serviceSpFooter {
    &_help {
      border-bottom: 1px solid $ds2-color-gray-25;
    }
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-otherService {
    display: block;
    margin-bottom: 12px;
    width: 100%;
  }

  .c-recommendViewBase {
    display: block;
    margin-bottom: 12px;
    width: 100%;
  }

  .c-recommendRuleBase {
    display: block;
    margin-bottom: 12px;
    width: 100%;
  }

  .c-faq {
    display: block;
    margin-bottom: 0;
    width: 100%;
  }

  .c-example {
    display: block;
    margin-bottom: 0;
    width: 100%;
  }

  .c-rating {
    display: block;
    margin-bottom: 0;
    width: 100%;
  }

  .c-serviceSpFooter {
    &_images {
      padding: 16px;
    }

    &_imageMethod {
      text-align: center;
    }

    &_imageCoin {
      margin-top: 12px;
      text-align: center;
    }
  }

  .c-share {
    margin: 0 auto;
    padding: 24px 0;
    background: $ds2-color-gray-25;

    &_social {
      height: 32px;
    }
  }

  .c-violation {
    display: flex;
    margin-top: 12px;
    font-size: 12px;

    align-items: center;
    justify-content: center;
  }

  .c-serviceSpFooterListItem {
    display: flex;
    padding: 8px 16px;
    border-top: 1px solid $ds2-color-gray-25;
    color: $ds2-color-gray-900;
    font-size: 14px;

    justify-content: space-between;

    &_columnLeft {
      align-self: center;

      &-guide {
        margin-right: 8px;

        align-self: flex-start;
        flex-basis: 58px;
      }
    }

    &_columnMiddle {
      &-guide {
        flex: 1px;
        margin-right: 8px;
      }
    }

    &_columnRight {
      color: $ds2-color-gray-600;
      text-align: right;

      align-self: center;
    }

    &_guideCaption {
      color: $ds2-color-brand-secondary-700;
      font-weight: bold;
    }

    &_guideDescription {
      font-size: 12px;
    }
  }
}
</style>
