<template>
  <img
    v-intersect-on
    :src="src"
    :alt="alt"
    class="d-lazyImage"
    :class="classes"
    @intersect="onIntersect"
    @load="onLoad"
  />
</template>

<script>
/** @type {import('vue').ComponentOptions} */
const dLazyImage = {
  name: 'DLazyImage',
  props: {
    imageUrl: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      src: '',
      isLoaded: false
    }
  },
  computed: {
    classes() {
      return {
        'd-lazyImage-loaded': this.isLoaded
      }
    }
  },
  methods: {
    onIntersect() {
      this.src = this.imageUrl
    },
    onLoad() {
      this.isLoaded = true
    }
  }
}
export default dLazyImage
</script>

<style lang="scss" scoped>
.d-lazyImage {
  opacity: 0;
  transition: opacity 0.2s ease-out;
  &-loaded {
    opacity: 1;
  }
}
</style>
