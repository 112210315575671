<template>
  <DLazyImage
    class="d-userIcon"
    :class="{
      'd-userIcon-withBorder': hasBorder,
      'd-userIcon-business': isBusiness
    }"
    :image-url="$coconala.userIconURLV2(filePath, width, height, isFromSystem)"
    :style="style"
  />
</template>
<script>
import DLazyImage from '~/components/atoms/DS2/DLazyImage'

/** @type {import('vue').ComponentOptions} */
const dUserIcon = {
  name: 'DUserIcon',
  components: {
    DLazyImage
  },
  props: {
    filePath: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: 'user'
    },
    isFromSystem: {
      type: Boolean,
      defalut: false
    },
    // 取得サイズ（横）
    width: {
      type: Number,
      default: 100
    },
    // 取得サイズ（縦）
    height: {
      type: Number,
      default: 100
    },
    hasBorder: {
      type: Boolean,
      defalut: false
    },
    isBusiness: {
      type: Boolean,
      defalut: false
    },
    // 表示サイズ（正方形）
    // isSPなどでPCとのサイズ指定の出しわけをする際はこのpropsを使わず、
    // コンポーネント呼び出し元でサイズを直接指定してしまうのを推奨（レイアウトシフト対策のため）
    size: {
      type: String,
      default: ''
    }
  },
  computed: {
    style() {
      if (!this.size) return {}
      return { width: this.size, height: this.size }
    }
  }
}
export default dUserIcon
</script>

<style lang="scss" scoped>
.d-userIcon {
  max-width: 172px;
  max-height: 172px;
  width: 100%;
  height: auto;
  border-radius: 50%;

  &-withBorder {
    border: 1px solid $ds2-color-gray-100;

    &.d-userIcon {
      &-business {
        border-color: $ds2-color-business-navy-800;
      }
    }
  }
}
</style>
