import { render, staticRenderFns } from "./ContentsSpecifications.vue?vue&type=template&id=691e3fa2&scoped=true&"
import script from "./ContentsSpecifications.vue?vue&type=script&lang=js&"
export * from "./ContentsSpecifications.vue?vue&type=script&lang=js&"
import style0 from "./ContentsSpecifications.vue?vue&type=style&index=0&id=691e3fa2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "691e3fa2",
  null
  
)

export default component.exports