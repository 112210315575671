<template>
  <div>
    <div
      class="c-serviceContentsMenuPcFixedAdjust"
      v-if="isFixedMenu && isPC"
    ></div>

    <div
      class="c-serviceContentsMenuPc"
      :class="{ 'c-serviceContentsMenuPc-fixed': isFixedMenu }"
      v-scroll="handleScroll"
    >
      <div
        class="c-serviceContentsMenuPc_items"
        v-if="isPC"
      >
        <div class="c-serviceContentsMenuPc_item">
          <a
            href="#"
            v-scroll-to="{ el: '#serviceContentsSummary', offset: scrollToOffset }"
            :class="{ '-active': isActive.summary }"
          >
            <div>{{ $t('components.contentsMenuPc.description') }}</div>
          </a>
        </div>
        <div class="c-serviceContentsMenuPc_item">
          <a
            href="#"
            v-scroll-to="{ el: '#serviceContentsNote', offset: scrollToOffset }"
            :class="{ '-active': isActive.note }"
          >
            <div>{{ $t('components.contentsMenuPc.purchase_request') }}</div>
          </a>
        </div>
        <div
          class="c-serviceContentsMenuPc_item"
          v-if="hasFaqs"
        >
          <a
            href="#"
            v-scroll-to="{ el: '#serviceContentsFaq', offset: scrollToOffset }"
            :class="{ '-active': isActive.faq }"
          >
            <div>{{ $t('components.contentsMenuPc.questions') }}</div>
          </a>
        </div>
        <div
          class="c-serviceContentsMenuPc_item"
          v-if="hasSampleMessages"
        >
          <a
            href="#"
            v-scroll-to="{ el: '#serviceContentsExample', offset: scrollToOffset }"
            :class="{ '-active': isActive.example }"
          >
            <div>{{ $t('components.contentsMenuPc.sample_answer') }}</div>
          </a>
        </div>
        <div
          class="c-serviceContentsMenuPc_item"
          v-if="$serviceOverallRatingCountReactive()"
        >
          <a
            href="#"
            v-scroll-to="{ el: '#serviceContentsRating', offset: scrollToOffset }"
            :class="{ '-active': isActive.rating }"
            @click="onClickScrollToRatingMenu"
          >
            <div>{{ $t('components.contentsMenuPc.evaluation') }}</div>
          </a>
        </div>
        <div class="c-serviceContentsMenuPc_item c-serviceContentsMenuPc_fav">
          <fav-button-v2
            :is-favorited="isFavorited"
            :fav-count="favCount"
            :service-id="serviceId"
            type="TextCount"
            v-if="!isMyService"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const FOOTER_MARGIN_TOP = 112

import FavButtonV2 from '~/components/molecules/buttons/FavButtonV2'

// vuex
import { mapGetters } from 'vuex'

export default {
  name: 'ContentsMenuPc',
  components: {
    FavButtonV2
  },
  inject: {
    $serviceOverallRatingCountReactive: { default: () => 0 },
    impressionLogRatingServiceDetail: { default: () => ({}) }
  },
  props: {
    position: {
      type: Number
    },
    scrollToOffset: {
      type: Number
    },
    favCount: {
      type: Number,
      default: 0
    },
    serviceId: {
      type: Number,
      default: null
    },
    isFavorited: {
      type: Boolean,
      default: false
    },
    hasSampleMessages: {
      type: Boolean,
      default: false
    },
    hasFaqs: {
      type: Boolean,
      default: false
    },
    isMyService: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      elRectY: 0,
      footerRectY: 0,
      // activeの判定のためDOMの逆順で記載する
      isActive: {
        rating: false,
        example: false,
        faq: false,
        note: false,
        summary: true
      }
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC']),
    isFixedMenu() {
      return (
        this.elRectY &&
        this.position >= this.elRectY &&
        this.footerRectY - FOOTER_MARGIN_TOP > this.position
      )
    }
  },
  methods: {
    handleScroll() {
      let active = false
      const menuList = Object.keys(this.isActive)
      const scrollY = window.scrollY || window.pageYOffset
      const OFFSET_MARGIN = 10

      menuList.forEach(menu => {
        let rect = null
        const el = document.querySelector(`.js-contentsMenu-${menu}`)
        // 各メニューの開始座標の計算: ブラウザからの座標 + スクロール量 + オフセット量
        // 開閉コンテンツがあることやメニューが可変なためスクロール毎に計算している
        if (el) {
          rect = el.getBoundingClientRect().top + scrollY + this.scrollToOffset - OFFSET_MARGIN
        }

        // 現在のスクロール量をメニューのページ下からチェックしていき開始座標を超えたらアクティブを立てる
        if (rect && scrollY >= rect && !active) {
          this.isActive[menu] = true
          active = true
        } else {
          this.isActive[menu] = false
        }
      })

      // アクティブがなかった場合（ページ開始から最初のメニューまで）最初のメニューをアクティブにする
      if (!active) {
        this.isActive[menuList.slice(-1)[0]] = true
      }

      // footerの座標取得
      const footer = document.querySelector('.js-the-footer')
      if (footer) {
        const footerRect = footer.getBoundingClientRect().top
        this.footerRectY = footerRect + scrollY
      }
    },
    async onClickScrollToRatingMenu() {
      const detailJson = JSON.stringify({
        ...this.impressionLogRatingServiceDetail,
        click_point: 'evaluation_tab'
      })
      try {
        await this.$store.dispatch('big_query/log_impression/addLogImpression', {
          action: 'view_service_evaluation',
          detailJson
        })
      } catch (e) {
        this.$sentry.captureException(e)
      }
    }
  },
  mounted() {
    const elRect = this.$el.getBoundingClientRect().top
    const scrollY = window.scrollY || window.pageYOffset
    this.elRectY = elRect + scrollY
  }
}
</script>
<style lang="scss" scoped>
.c-serviceContentsMenuPcFixedAdjust {
  /* height + margin-bottom */
  height: 64px;
}

.c-serviceContentsMenuPc {
  margin-bottom: 32px;
  padding-top: 4px;
  height: 52px;
  background: $color-white;
  box-shadow: inset 0 -1px 0 0 $ds2-color-gray-50;
  font-size: 15px;

  &-fixed {
    position: fixed;
    top: 0;
    z-index: z(sub-header, main);
    margin-bottom: 0;
    width: 1040px;
  }

  &_items {
    display: flex;
    width: 690px;
  }

  &_item {
    align-self: center;

    a {
      display: flex;
      overflow: hidden;
      padding: 0 8px 4px;
      height: 48px;
      color: $ds2-color-gray-700;

      align-items: center;
      justify-content: center;

      &.-active {
        padding-bottom: 0;
        border-bottom: 4px solid $ds2-color-brand-primary;
        color: $ds2-color-brand-primary;
        font-weight: bold;
      }

      &:hover {
        @include link-effect;
      }
    }
  }

  &_fav {
    margin-bottom: 6px;
    margin-left: auto;
    width: 140px;

    flex-basis: 140px;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-serviceContentsMenuPc {
    display: none;
  }
}
</style>
