<template>
  <div class="c-serviceDetailContents">
    <ContentsMenuPc
      :position="position"
      :scroll-to-offset="scrollToOffset"
      :fav-count="serviceDetail.serviceDetail.favCount"
      :service-id="serviceDetail.serviceDetail.id"
      :is-favorited="serviceDetail.buyer.isFavorited"
      :has-sample-messages="serviceDetail.serviceDetail.sampleMessagesList.length ? true : false"
      :has-faqs="serviceDetail.serviceDetail.faqsList.length ? true : false"
      :is-my-service="isMyService"
    />
    <div class="c-serviceContents">
      <div
        class="c-contentsImagesProduction"
        v-if="isContentsImagesProduction"
      >
        <ContentsImagesProduction
          :service-name="serviceDetail.serviceDetail.name"
          :service-image-list="serviceDetail.serviceDetail.serviceImagesList"
          :service-movie-list="serviceDetail.serviceDetail.serviceMoviesList"
          :movie-priority-flag="serviceDetail.serviceDetail.moviePriorityFlag"
          v-show="isPC"
        />
      </div>

      <div
        class="c-serviceContentsPurchaseSp"
        v-show="isSP || isTB"
      >
        <div
          class="c-phoneServiceSp"
          v-if="serviceDetail.serviceDetail.serviceClass === serviceClass.phone"
        >
          <HeadingSecond heading="電話相談申し込みの流れ" />
          <div class="c-phoneServiceSp_inner">
            <div class="c-phoneServiceSp_image">
              <img
                src="@/assets/images/pages/services/phone-service-sp.png"
                alt="電話相談申し込みの流れ"
                width="292"
                height="28"
                loading="lazy"
                decoding="async"
              />
            </div>
            <div class="c-phoneServiceSp_notice">
              ※ご利用料金は通話時間に応じて、通話後に決済されます
            </div>
          </div>
        </div>
        <ContentsPurchaseSp
          :service-detail="serviceDetail"
          :total-price="totalPrice"
          :url-dm="urlDm"
          :url-customize="urlCustomize"
          :is-customize="isCustomize"
          :is-my-service="isMyService"
          :is-service-reservation="isServiceReservation"
          :service-id="serviceDetail.serviceDetail.id"
          :fav-count="serviceDetail.serviceDetail.favCount"
          :is-favorited="serviceDetail.buyer.isFavorited"
          :set-reservation="setReservation"
          v-if="!isBlockee"
          @emitReservation="$emit('emitReservation', true)"
        />
      </div>

      <div
        class="c-serviceContentsSpecifications"
        id="serviceContentsSpecifications"
      >
        <ContentsSpecifications
          :specifications="serviceDetail.serviceDetail.specificationsList"
          :video-type="serviceDetail.serviceDetail.videoType"
          :is-parent-category-divination-phone="isParentCategoryDivinationPhone"
        />
      </div>

      <div
        class="c-serviceDetailImagesSpPhone"
        v-if="isServiceDetailImagesSpPhone"
      >
        <ServiceDetailImagesSp
          :service-name="serviceDetail.serviceDetail.name"
          :service-image-list="serviceDetail.serviceDetail.serviceImagesList"
          :service-movie-list="serviceDetail.serviceDetail.serviceMoviesList"
          :movie-priority-flag="serviceDetail.serviceDetail.moviePriorityFlag"
          :is-loading="isLoading"
          v-show="isSP || isTB"
        />
      </div>

      <div
        class="c-serviceContentsSummary"
        :class="{ 'c-serviceContentsSummary-consultation': isContentsImagesConsultation }"
      >
        <div
          class="c-contentsImagesConsultation"
          v-if="isContentsImagesConsultation"
        >
          <ContentsImagesConsultation
            :service-name="serviceDetail.serviceDetail.name"
            :service-image-list="serviceDetail.serviceDetail.serviceImagesList"
            :service-movie-list="serviceDetail.serviceDetail.serviceMoviesList"
            :movie-priority-flag="serviceDetail.serviceDetail.moviePriorityFlag"
            v-show="isPC"
          />
        </div>
        <h3
          class="c-spContents js-contentsMenu-summary"
          id="serviceContentsSummary"
        >
          {{ $t('service_detail.section_title.description') }}
        </h3>
        <ContentsFreeText
          class="c-spContents c-serviceContentsSummary_summary"
          :free-text="serviceDetail.serviceDetail.head"
        />

        <h3
          class="c-spContents js-contentsMenu-note"
          id="serviceContentsNote"
        >
          {{ $t('service_detail.section_title.purchase_request') }}
        </h3>
        <ContentsFreeText
          class="c-spContents c-serviceContentsSummary_note"
          :free-text="serviceDetail.serviceDetail.body"
        />

        <h3
          class="c-spContents c-clearFix"
          v-if="serviceDetail.serviceDetail.optionsList.length"
        >
          {{ $t('service_detail.section_title.charged_option') }}
          <ClientOnly>
            <ContentsTooltip
              v-if="isPC"
              class="c-tooltip"
            >
              <div
                class="c-tooltip_help"
                slot="body"
                v-html="optionToolTipText"
              ></div>
            </ContentsTooltip>
          </ClientOnly>
        </h3>
        <div
          class="c-serviceOptions"
          v-if="serviceDetail.serviceDetail.optionsList.length"
        >
          <!-- TODO: ServiceDetailContentsとServiceDetailPurchaseで同じ処理をしているのでまとめたい。ただデータの受け渡しが増えるので考えどころ -->
          <ul
            class="c-serviceOptionList"
            :class="{ 'c-serviceOptionList-customizeRequired': isCustomizeRequired }"
          >
            <li
              class="c-serviceOptionItem"
              :class="{ 'c-serviceOptionItem-active': isActiveOption(option.id) }"
              v-for="option in serviceDetail.serviceDetail.optionsList"
              :key="option.id"
            >
              <label class="c-serviceOptionItem_label">
                <div class="c-serviceOptionItem_name">
                  <!-- prettier-ignore -->
                  <span
                    v-if="isCustomizeRequired"
                    class="c-serviceOptionItem_text"
                  >{{ option.title }}</span>
                  <CCheckbox
                    v-else
                    @input="updateOptions($event)"
                    :native-value="option.id"
                    v-model="innerOptions"
                    size="small"
                  >
                    <span class="c-serviceOptionItem_text">{{ option.title }}</span>
                  </CCheckbox>
                </div>
                <div class="c-serviceOptionItem_price">
                  <span class="c-serviceOptionItem_prefix">＋</span>
                  <!-- prettier-ignore -->
                  <span class="c-serviceOptionItem_pricewot">{{ $translate.multilingualCurrency(option.priceWot) }}</span>
                </div>
              </label>
            </li>
          </ul>
        </div>
        <DTranslatedByGoogle v-if="$translate.isTranslatableTarget()" />
      </div>
    </div>
  </div>
</template>
<script>
import ContentsFreeText from './components/ContentsFreeText'
import ContentsMenuPc from './components/ContentsMenuPc'
import ContentsPurchaseSp from './components/ContentsPurchaseSp'
import ContentsSpecifications from './components/ContentsSpecifications'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'ServiceDetailContents',
  components: {
    ContentsFreeText,
    ContentsMenuPc,
    ContentsPurchaseSp,
    ContentsSpecifications,
    ContentsImagesConsultation: () => import('./components/ContentsImagesConsultation'),
    ContentsImagesProduction: () => import('./components/ContentsImagesProduction'),
    ContentsTooltip: () => import('./components/ContentsTooltip'),
    HeadingSecond: () => import('./components/HeadingSecond'),
    ServiceDetailImagesSp: () => import('~/components/organisms/ServiceDetailImagesSp'),
    DTranslatedByGoogle: () => import('~/components/molecules/DS2/DTranslatedByGoogle')
  },
  props: {
    position: {
      type: Number
    },
    scrollToOffset: {
      type: Number
    },
    serviceDetail: {
      type: Object,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    },
    urlDm: {
      type: String,
      required: true
    },
    urlCustomize: {
      type: String,
      required: true
    },
    isCustomize: {
      type: Boolean,
      required: true
    },
    isServiceReservation: {
      type: Boolean,
      required: true
    },
    isBlockee: {
      type: Boolean,
      required: true
    },
    isMyService: {
      type: Boolean,
      default: false
    },
    setReservation: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isParentCategoryDivinationPhone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerOptions: this.options
    }
  },
  watch: {
    options: function (array) {
      this.innerOptions = array
    }
  },
  computed: {
    ...mapState('constants', ['serviceClass']),
    ...mapGetters('ui', ['isPC', 'isTB', 'isSP']),
    ...mapGetters('auth', ['isLoggedIn']),
    optionToolTipText() {
      const prefix = this.$t('components.service_detail_contents.prefix')
      const suffix = this.$t('components.service_detail_contents.suffix')
      const price = this.$util.numberWithDelimiter(this.serviceDetail.serviceDetail.priceWot)
      return `${prefix}${price}${suffix}`
    },
    // ContentsImagesProductionを表示する条件（デバイス判定は除く）
    isContentsImagesProduction() {
      return (
        this.serviceDetail.serviceDetail.formatType === 'production' &&
        (this.serviceDetail.serviceDetail.serviceImagesList.length ||
          this.serviceDetail.serviceDetail.serviceMoviesList.length)
      )
    },
    // ContentsImagesConsultationを表示する条件（デバイス判定は除く）
    isContentsImagesConsultation() {
      return (
        this.serviceDetail.serviceDetail.formatType === 'consultation' &&
        (this.serviceDetail.serviceDetail.serviceImagesList.length ||
          this.serviceDetail.serviceDetail.serviceMoviesList.length)
      )
    },
    // ServiceDetailImagesSpPhoneを表示する条件（デバイス判定は除く）
    isServiceDetailImagesSpPhone() {
      return (
        this.serviceDetail.serviceDetail.serviceClass === this.serviceClass.phone &&
        (this.serviceDetail.serviceDetail.serviceImagesList.length ||
          this.serviceDetail.serviceDetail.serviceMoviesList.length)
      )
    },
    isCustomizeRequired() {
      return this.isCustomize && this.serviceDetail.serviceDetail.customizeRequiredFlag
    }
  },
  methods: {
    updateOptions(option) {
      this.$emit('changeOption', this.innerOptions)
    },
    isActiveOption(id) {
      return this.options.some(value => value === id)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-tooltip {
  display: inline;

  ::v-deep .dropdown-menu {
    width: 240px !important;
  }

  &_help {
    font-weight: normal;
    font-size: 12px;
  }
}

.c-serviceDetailContents {
  h3 {
    margin-bottom: 16px;
    font-weight: bold;
    font-size: 16px;
  }

  ::v-deep {
    .d-translatedByGoogle {
      margin-top: 40px;
    }
  }
}

.c-serviceContentsSummary {
  margin-bottom: 60px;

  &-consultation {
    min-height: 258px;
  }

  &_summary {
    margin-bottom: 32px;
  }

  &_note {
    margin-bottom: 32px;
  }
}

.c-serviceOptions {
  position: relative;
  border: 1px solid $ds2-color-gray-100;
  border-radius: 3px;
  background: $color-white;
}

.c-serviceOptionList {
  &-customizeRequired {
    pointer-events: none;
  }
}

.c-serviceOptionItem {
  border-top: 1px dotted $ds2-color-gray-50;

  &:first-child {
    border-top: 0;
  }

  &_label {
    display: flex;
    padding: 12px 16px;
    line-height: 24px;
    cursor: pointer;

    align-items: center;
    justify-content: space-between;
  }

  &_name {
    margin: 0;
  }

  &_text {
    display: inline-block;
    width: 480px;
    font-size: 14px;
  }

  &_price {
    text-align: right;
    font-size: 15px;
    line-height: 1;
  }

  &_prefix {
    margin-right: 2px;
    color: $ds2-color-brand-secondary-700;
  }

  &-active {
    background: $ds2-color-brand-secondary-50;
    font-weight: bold;
  }
}

.c-contentsImagesProduction {
  min-height: 358px;
}

.c-contentsImagesConsultation {
  float: right;
  margin: 0 0 20px 20px;
  min-height: 258px;
  width: 230px;
}

.c-clearFix {
  clear: both;
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-spContents {
    margin: 0 auto;
    padding: 0 12px;
  }

  .c-serviceDetailContents {
    h3 {
      margin-bottom: 8px;
      font-size: 14px;
    }

    ::v-deep {
      .d-translatedByGoogle {
        padding: 8px;
        margin: 0;
        background-color: $ds2-color-gray-25;
      }
    }
  }

  .c-serviceContentsSummary {
    margin-bottom: 0;

    &_summary {
      margin-bottom: 16px;
    }

    &_note {
      margin-bottom: 16px;
    }
  }

  .c-serviceOptions {
    border-right: 0;
    border-left: 0;
    border-radius: 0;

    .c-serviceOptionItem {
      border-top: 1px solid $ds2-color-gray-100;

      &:first-child {
        border-top: 0;
      }

      &_label {
        padding: 12px;
      }

      &_name {
        flex: 1 1 auto;
      }

      &_text {
        width: 100%;
        font-size: 14px;
      }

      &_price {
        flex: 1 0 120px;
        width: 120px;
      }
    }
  }

  .c-contentsImagesProduction {
    display: none;
  }

  .c-contentsImagesConsultation {
    display: none;
  }

  .c-phoneServiceSp {
    border-bottom: 1px solid $ds2-color-gray-50;

    &_inner {
      padding: 16px 12px;
    }

    &_image {
      margin-bottom: 12px;
      text-align: center;
    }

    &_notice {
      color: $color-red-500;
      text-align: center;
      font-size: 11px;
    }
  }
}
</style>
