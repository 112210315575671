<template>
  <section class="c-contentsSpecifications">
    <div class="c-contentsSpecificationsInner">
      <table
        class="c-contentsSpecificationsInnerList"
        data-translate
      >
        <tbody>
          <tr
            class="c-contentsSpecificationsInnerList_value"
            v-if="isNotProvisionFormatCategoryAndVideoTypeTool"
          >
            <th class="c-contentsSpecificationsInnerListType_value">提供形式</th>
            <td class="c-contentsSpecificationsInnerListType_value">
              ビデオチャット打ち合わせ可能
            </td>
          </tr>
          <tr
            v-for="(specification, index) in specifications"
            :key="`specification${index}`"
            :class="`c-contentsSpecificationsInnerList_${classNameSuffix(specification)}`"
          >
            <th>
              {{ specification.title }}
            </th>
            <td>
              <div v-if="specification.bodyType === 'value' && specification.bodyJson">
                <span
                  class="c-tagList"
                  v-if="isParentCategoryDivinationPhone"
                >
                  <a
                    class="c-tagList_tag"
                    :href="categoryDivinationPhoneUrl"
                  >
                    電話占い
                  </a>
                </span>
                <span v-else>
                  {{ parseJson(specification.bodyJson) }}
                </span>
                <span
                  class="c-contentsSpecificationsInnerList_videoType"
                  v-if="specification.title === '提供形式' && videoType === videoTypeTool"
                >
                  ビデオチャット打ち合わせ可能
                </span>
              </div>

              <div
                v-else-if="
                  specification.bodyType === 'tags' &&
                  specification.serviceSpecificationDetailsList.length > 0
                "
              >
                <div class="c-tagList">
                  <a
                    v-for="(tag, tagIndex) in specification.serviceSpecificationDetailsList"
                    :key="`tag-${index}-${tagIndex}`"
                    class="c-tagList_tag"
                    :href="tag.url"
                  >
                    {{ tag.body }}
                  </a>
                </div>
              </div>

              <div v-else-if="specification.bodyType === 'check'">
                <span v-if="parseJson(specification.bodyJson)">
                  <CoconalaIcon
                    name="check"
                    class="c-contentsSpecificationsInnerList_checked"
                  />
                </span>
                <span v-else>
                  <CoconalaIcon
                    name="minus"
                    class="c-contentsSpecificationsInnerList_minus"
                  />
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>
<script>
import { mapState } from 'vuex'
const VIDEO_TYPE_TOOL = 1

export default {
  name: 'ContentsSpecifications',
  props: {
    specifications: {
      type: Array,
      required: true
    },
    videoType: {
      type: Number,
      default: null
    },
    isParentCategoryDivinationPhone: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      videoTypeTool: VIDEO_TYPE_TOOL
    }
  },
  computed: {
    ...mapState('constants', ['VIRTUAL_CATEGORY']),
    styleSpecificationDetail() {
      const firstSpecificationDetail = this.serviceStyle?.serviceSpecificationDetailsList?.[0]
      if (!firstSpecificationDetail) {
        return null
      }
      return {
        title: this.serviceStyle.title,
        ...firstSpecificationDetail
      }
    },
    isNotProvisionFormatCategoryAndVideoTypeTool() {
      return (
        !this.specifications.some(item => item.title === '提供形式') &&
        this.videoType === VIDEO_TYPE_TOOL
      )
    },
    categoryDivinationPhoneUrl() {
      return `/categories/${this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.CATEGORY_ID}?service_kind=${this.VIRTUAL_CATEGORY.DIVINATION_PHONE.CONDITION.SERVICE_CLASS}`
    }
  },
  methods: {
    parseJson(json) {
      return JSON.parse(json)
    },
    classNameSuffix(specification) {
      return this.isParentCategoryDivinationPhone ? 'tags' : specification.bodyType
    }
  }
}
</script>
<style lang="scss" scoped>
.c-contentsSpecifications {
  margin-bottom: 32px;
  padding: 16px;
  border: 1px solid $ds2-color-gray-50;
  border-radius: 3px;
}

.c-contentsSpecificationsInner {
  display: flex;
  font-size: 14px;

  &_left {
    flex: 0 0 25%;
    padding-right: 16px;
  }

  &_right {
    flex: 1 1 auto;
    padding-left: 16px;
    border-left: 1px solid $ds2-color-gray-50;
  }
}

.c-contentsSpecificationsInnerList {
  tr {
    th {
      padding-right: 12px;
      vertical-align: top;
      text-align: left;
      white-space: nowrap;
      font-weight: bold;
    }

    td {
      padding: 0 0 0 12px;
      vertical-align: top;
    }

    &:not(:last-child) {
      th,
      td {
        padding-bottom: 12px;
      }
      &.c-contentsSpecificationsInnerList_tags {
        th,
        td {
          padding-bottom: 4px;
        }
      }
    }
  }

  &_checked {
    color: $ds2-color-brand-primary-600;
  }

  &_minus {
    color: $ds2-color-gray-400;
  }

  &_videoType {
    margin-left: 4px;
    color: $ds2-color-gray-600;
    font-size: 12px;
  }
}

.c-tagList {
  display: flex;

  flex-wrap: wrap;

  &_tag {
    @include link-effect;

    margin: 0 8px 8px 0;
    padding: 6px;
    border: 1px solid $ds2-color-gray-50;
    border-radius: 4px;
    color: $ds2-color-font-base;
    white-space: nowrap;
    font-size: 11px;
    line-height: 1;
    &-style {
      margin-right: 0;
    }
  }
}

/* stylelint-disable */
@media screen and (min-width: 0\0) and (min-resolution: +72dpi) {
  .c-tagList {
    max-width: 250px;
  }
}
/* stylelint-enable */

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-contentsSpecifications {
    margin-bottom: 0;
    padding: 16px 12px;
    border: 0;
  }

  .c-contentsSpecificationsInner {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $ds2-color-gray-50;
    font-size: 13px;

    &_left {
      flex: 1 1 auto;
      padding-right: 0;
    }

    &_right {
      padding-left: 0;
      border-left: 0;
    }
  }

  .c-contentsSpecificationsInnerList {
    display: block;
    width: 100%;

    tbody {
      display: block;
      width: 100%;
    }

    tr {
      display: flex;
      width: 100%;

      justify-content: space-between;

      th {
        display: block;
        padding-right: 0;
        padding-bottom: 12px;
        white-space: nowrap;
      }

      td {
        display: block;
        flex: 1 1 auto;
        padding: 0 0 0 0;
        padding-bottom: 12px;
        text-align: right;
      }

      &:not(:last-child) {
        th,
        td {
          padding-bottom: 12px;
        }
      }

      &.c-contentsSpecificationsInnerList_tags {
        flex-direction: column;

        justify-content: flex-start;

        th,
        td {
          display: block;
          padding-bottom: 4px;
          width: 100%;
        }
      }
    }
  }
}
</style>
