import { ProviderCouponFollowupReplyStatus } from '~/stub/domain/enumeration/provider_coupon_followup_reply_status_pb'
import { ProviderCouponAccessor } from '~/api/accessors/provider_coupon/provider-coupon-accessor'
import { mapGetters } from 'vuex'

// 出品者クーポンの受け取り状況
const { PROVIDER_COUPON_FOLLOWUP_REPLY_STATUS_UNSPECIFIED } = ProviderCouponFollowupReplyStatus

type CheckStatusAndOpenBalloonParams = {
  providerId: number
  isFollowed: boolean
  isProviderSearch?: boolean
}

export default {
  data() {
    return {
      isFirstMouseover: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn'])
  },
  methods: {
    getFollowupReplyStatusResponse(providerId: number) {
      const params = { providerId }
      const apiAccessor = new ProviderCouponAccessor(this.$store.state)
      return apiAccessor
        .getFollowupReplyStatus(params)
        .then(res => {
          this.providerCouponFollowupReplyStatus = res.providerCouponFollowupReplyStatus
          this.isFirstMouseover = true
          // ユーザー影響が少ないためエラーの時は握りつぶす
        })
        .catch(e => {
          this.$sentry.captureException(e)
        })
    },
    showCouponNotificationBalloon(isProviderSearch?: boolean) {
      if (
        this.providerCouponFollowupReplyStatus === PROVIDER_COUPON_FOLLOWUP_REPLY_STATUS_UNSPECIFIED
      ) {
        if (isProviderSearch) {
          this.$emit('get-user-id-open-balloon')
        } else {
          this.isShowCouponNotificationBalloon = true
        }
      }
    },
    async checkStatusAndOpenBalloon(params: CheckStatusAndOpenBalloonParams) {
      if (!this.isLoggedIn) return
      // マウント時に出品者検索画面でAPIコールすると大量に呼ばれてしまうので、初回マウスオーバー時のみコールする
      if (!this.isFirstMouseover) {
        await this.getFollowupReplyStatusResponse(params.providerId)
      }
      if (
        this.providerCouponFollowupReplyStatus ===
          PROVIDER_COUPON_FOLLOWUP_REPLY_STATUS_UNSPECIFIED &&
        params.isFollowed
      ) {
        if (params.isProviderSearch) {
          this.$emit('get-user-id-open-balloon')
        } else {
          this.isShowCouponNotificationBalloon = true
        }
      }
    }
  }
}
