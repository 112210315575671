<template>
  <div class="c-serviceDetailTitle">
    <div class="c-heading">
      <div class="c-heading_row">
        <div
          class="c-heading_icon"
          v-if="serviceDetail.serviceDetail.proFlag"
        >
          <ProIcon width="30px" />
        </div>
        <div
          class="c-heading_icon"
          v-else-if="serviceDetail.serviceDetail.certificationDivinationFlag"
        >
          <DivinationIcon width="30px" />
        </div>
        <div class="c-heading_overview c-overview">
          <h1 v-if="serviceDetail.serviceDetail.overview">
            <p class="c-overview_overview">
              {{ serviceDetail.serviceDetail.overview }}
            </p>
            <p class="c-overview_text">{{ serviceDetail.serviceDetail.catchphrase }}</p>
          </h1>
          <h1 v-else>
            <p class="c-overview_overview">
              {{ serviceDetail.serviceDetail.name }}
            </p>
          </h1>
          <div class="c-heading_labels">
            <div
              class="c-phoneLabel"
              v-if="serviceDetail.serviceDetail.serviceClass === serviceClass.phone"
            >
              <span class="c-phoneLabel_label">{{ $t('service_detail.label.phone_service') }}</span>
            </div>
            <div
              class="c-shipmentLabel"
              v-if="serviceDetail.serviceDetail.shipmentFlag"
            >
              <span class="c-shipmentLabel_label">{{
                $t('service_detail.label.goods_delivery')
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="c-performance">
      <div class="c-performance_row">
        <div class="c-performance_item c-performance_rating">
          <div class="c-performance_label">
            {{ $t('service_detail.label.evaluation') }}
          </div>
          <BalloonDropdown
            v-if="isRating"
            class="c-performance_dropdown"
            position="bottom-center"
            :triggers="isPC ? ['hover'] : []"
          >
            <div
              slot="trigger"
              v-scroll-to="{ el: '#serviceContentsRating', offset: scrollToOffset }"
              class="c-performance_content c-performance_content-rating"
              @click="onClickScrollToRatingMenu"
            >
              <span class="c-rating">
                <CRatingStars
                  :size="14"
                  :score="serviceDetailRatings.overallRating.indicator"
                  class="c-ratingPc"
                />
                <CoconalaIcon
                  name="star"
                  class="c-ratingSp c-ratingSp-active"
                />
              </span>

              <span class="c-ratingIndicatorCount"
                ><strong class="c-ratingIndicator">{{
                  displayFixed(serviceDetailRatings.overallRating.indicator)
                }}</strong
                ><!--
              -->({{ $util.numberWithDelimiter(serviceOverallRatingCount) }})</span
              >
            </div>
            <ClientOnly>
              <div
                v-if="isPC"
                v-intersect-on.each
                @intersect="changeActiveRatingBalloon"
              >
                <div class="c-ratingBalloon_rating">
                  <span class="c-rating">
                    <CRatingStars
                      :size="14"
                      :score="serviceDetailRatings.overallRating.indicator"
                    /> </span
                  ><span class="c-ratingBalloon_indicatorLabel">{{
                    $t('service_detail.label.comprehensive_evaluation')
                  }}</span
                  ><!--
                  --><span class="c-ratingBalloon_indicator">{{
                    displayFixed(serviceDetailRatings.overallRating.indicator)
                  }}</span>
                </div>
                <div class="c-ratingBalloon_count">
                  {{ $util.numberWithDelimiter(serviceOverallRatingCount)
                  }}{{ $t('service_detail.label.evaluation_of_the_case') }}
                </div>
                <DRatingChart
                  class="c-ratingBalloon_chart"
                  :rating-stars="serviceDetailRatings.starsList"
                  :overall-rating-count="serviceOverallRatingCount"
                  :is-opened="isOpenedChart"
                />
                <a
                  href="#"
                  v-scroll-to="{ el: '#serviceContentsRating', offset: scrollToOffset }"
                  class="c-ratingBalloon_scrollContentsRating"
                  @click="onClickScrollToRatingMenu"
                  >{{ $t('service_detail.label.all_ratings') }}</a
                >
              </div>
            </ClientOnly>
          </BalloonDropdown>
          <div
            v-else
            class="c-performance_content c-performance_content-rating"
          >
            <span class="c-rating">
              <CRatingStars
                :size="14"
                :score="0"
                class="c-ratingPc"
              />
              <CoconalaIcon
                name="star"
                class="c-ratingSp"
              />
            </span>

            -
          </div>
        </div>
        <div class="c-performance_item c-performance_sales">
          <div class="c-performance_label">
            {{ $t('service_detail.label.sales_results') }}
          </div>
          <div class="c-performance_content">
            <strong>{{
              $util.numberWithDelimiter(serviceDetail.serviceDetail.performanceCount)
            }}</strong
            >{{ $t('cases') }}
          </div>
        </div>
      </div>

      <div class="c-performance_row">
        <div class="c-performance_item c-performance_stock">
          <!-- 事前予約機能が使える場合はお願い中のみの表示にする -->
          <template v-if="canAdvanceReservation">
            <div class="c-performance_label">
              {{ $t('service_detail.label.currently_requesting') }}
            </div>
            <div class="c-performance_content">
              <strong>{{ serviceDetail.serviceDetail.openedTalkroomCount }}</strong
              >{{ $t('people') }}
            </div>
          </template>
          <template v-else>
            <div class="c-performance_label">
              {{ $t('service_detail.label.remaining') }}
            </div>
            <div class="c-performance_content">
              <strong>{{ serviceDetail.serviceDetail.orderStockCount }}</strong
              >{{ $t('maximum_number') }} /
              {{ $t('service_detail.label.currently_requesting') }}：<strong>{{
                serviceDetail.serviceDetail.openedTalkroomCount
              }}</strong
              >{{ $t('people') }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import ProIcon from '~/components/molecules/ProIcon'
import DivinationIcon from '~/components/molecules/Divination'

export default {
  name: 'serviceDetailTitle',
  components: {
    ProIcon,
    DivinationIcon,
    BalloonDropdown: () => import('~/components/molecules/BalloonDropdown'),
    DRatingChart: () => import('~/components/molecules/DS2/DRatingChart'),
    CRatingStars: () => import('~/components/atoms/CRatingStars')
  },
  inject: {
    $serviceOverallRatingCountReactive: { default: () => 0 },
    impressionLogRatingServiceDetail: { default: () => ({}) }
  },
  props: {
    serviceDetail: {
      type: Object,
      required: true
    },
    serviceDetailRatings: {
      type: Object,
      required: true
    },
    scrollToOffset: {
      type: Number
    }
  },
  data() {
    return {
      isOpenedChart: false
    }
  },
  computed: {
    ...mapState('constants', ['serviceClass']),
    ...mapGetters('ui', ['isPC']),
    ...mapGetters('pages/services/serviceDetail', ['canAdvanceReservation']),
    isRating() {
      return (
        this.serviceDetailRatings.overallRating &&
        this.serviceDetailRatings.overallRating.indicator &&
        this.serviceOverallRatingCount
      )
    },
    serviceOverallRatingCount() {
      return this.$serviceOverallRatingCountReactive()
    }
  },
  methods: {
    displayFixed(value, digit = 1) {
      return value.toFixed(digit)
    },
    changeActiveRatingBalloon(entry) {
      this.isOpenedChart = entry.isIntersecting
    },
    async onClickScrollToRatingMenu() {
      const detailJson = JSON.stringify({
        ...this.impressionLogRatingServiceDetail,
        click_point: 'search_guide'
      })
      try {
        await this.$store.dispatch('big_query/log_impression/addLogImpression', {
          action: 'view_service_evaluation',
          detailJson
        })
      } catch (e) {
        this.$sentry.captureException(e)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-heading {
  display: flex;

  &_row {
    display: flex;
  }

  &_icon {
    margin-right: 8px;

    ::v-deep .dropdown-menu {
      z-index: z(sub-header, tooltip);
    }
  }

  &_labels {
    margin-bottom: 12px;
  }
}

.c-phoneLabel {
  display: inline-block;
  margin-right: 8px;

  &_label {
    display: inline-block;
    padding: 0 8px;
    height: 20px;
    border: 1px solid $color-orange-500;
    color: $color-orange-500;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
  }
}

.c-shipmentLabel {
  display: inline-block;
  margin-right: 8px;

  &_label {
    display: inline-block;
    padding: 0 8px;
    height: 20px;
    border: 1px solid $ds2-color-gray-400;
    color: $ds2-color-gray-700;
    text-align: center;
    font-size: 10px;
    line-height: 18px;
  }
}

.c-overview {
  &_overview {
    margin: 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
  }

  &_text {
    margin: 0;
    color: $ds2-color-gray-600;
    font-weight: normal;
    font-size: 19px;
  }
}

.c-performance {
  display: flex;
  margin-top: 4px;

  &_row {
    display: flex;
  }

  &_item {
    display: inline-block;
    margin-right: 20px;
    line-height: 1;
  }

  &_label {
    display: inline-block;
    margin-right: 4px;
    padding: 4px 8px 5px;
    background: $ds2-color-gray-25;
    font-size: 12px;
  }

  &_dropdown {
    display: inline-flex;

    ::v-deep .dropdown {
      &.is-hoverable {
        &:hover {
          .c-performance {
            &_content {
              &-rating {
                border-radius: 4px;
                background: $ds2-color-gray-25;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    ::v-deep .dropdown-menu {
      transform: translate(-50%, 0);
    }

    ::v-deep .dropdown-content {
      padding: 16px 16px 8px;
      width: 280px;
    }
  }

  &_content {
    position: relative;
    top: 1px;
    display: inline-block;

    &-rating {
      padding: 2px;
    }
  }
}

.c-ratingStars {
  top: 1px;
}

.c-rating {
  display: inline-block;
  min-width: 76px;
}

.c-ratingIndicatorCount {
  border-bottom: 1px solid $ds2-color-gray-700;
}

.c-ratingIndicator {
  padding-right: 2px;
}

.c-ratingBalloon {
  &_rating {
    display: flex;

    align-items: center;
  }

  &_count {
    margin-top: 4px;
    color: $ds2-color-gray-600;
    font-size: 11px;
    line-height: 1.5;
  }

  &_indicatorLabel {
    margin: 0 2px;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.2;
  }

  &_indicator {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.2;
  }

  &_chart {
    margin-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $ds2-color-gray-50;

    ::v-deep .d-ratingChartItem {
      color: $ds2-color-gray-600;

      &_label,
      &_count {
        padding: 0;
      }

      &_bar {
        padding: 3px 4px;
      }
    }
  }

  &_scrollContentsRating {
    display: flex;
    margin-top: 4px;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    color: $ds2-color-brand-secondary;
    font-size: 12px;

    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $ds2-color-gray-25;
    }
  }
}

@media (min-width: breakpoint(ServiceDetail, M) + 1) {
  .c-ratingSp {
    display: none;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-heading {
    flex-direction: column;

    &_row:nth-child(n + 2) {
      margin-top: 4px;
    }
  }

  .c-overview {
    &_overview {
      font-size: 17px;
      line-height: 24px;
    }

    &_text {
      font-size: 12px;
    }
  }

  .c-performance {
    flex-direction: column;

    &_row:nth-child(n + 2) {
      margin-top: 4px;
    }
  }

  .c-rating {
    min-width: 12px;
  }

  .c-ratingSp {
    color: $ds2-color-gray-100;
    vertical-align: -1px;

    &-active {
      color: $ds2-color-star;
    }
  }

  .c-ratingPc {
    display: none !important;
  }
}
</style>
