<template>
  <component
    :is="isPC ? 'div' : 'a'"
    :href="isPC ? null : '/points/top'"
    class="c-couponBanner"
    v-intersect-translate.once
  >
    <div class="c-couponBanner_discountOverview">
      <template v-if="!$translate.isTranslatableTarget()">
        <div
          v-if="coupon.isExpiring"
          class="c-couponBanner_remainDays"
        >
          今日まで
        </div>
        <div
          v-else
          class="c-couponBanner_remainDays"
        >
          あと<span class="c-couponBanner_remainDaysCount">{{ couponRemainDays }}</span
          >日
        </div>
      </template>
      <div class="c-couponBanner_text">
        <span v-if="coupon.isMasterCategoryLimitCoupon">カテゴリ限定</span>
        <span class="c-couponBanner_discountValue">{{ coupon.discount }}</span>
        <span>{{ couponDiscountUnit }}クーポンが使えます</span>
      </div>
    </div>
    <a
      v-if="isPC"
      class="c-couponBanner_link"
      href="/points/top"
    >
      クーポン詳細を見る
    </a>
    <coconala-icon
      class="c-couponBanner_chevronRight"
      name="chevron-right"
      size="14px"
    />
  </component>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

/** @type {import('vue').ComponentOptions} */
const couponBanner = {
  name: 'CouponBanner',
  computed: {
    ...mapState('my/activities', ['userHeader']),
    ...mapGetters('ui', ['isPC']),
    couponDiscountUnit() {
      if (this.coupon?.discountType === 2) {
        return '%OFF'
      }
      return '円'
    },
    couponRemainDays() {
      // TODO クライアントサイドの時間がずれている場合の対処
      // クライアントサイドでgrpc-web使う場合はonHeadersでdateヘッダが取れない
      const expireDateTimestamp = this.coupon?.expireDate || 0
      const expireDate = this.$util.dayjs.unix(expireDateTimestamp).startOf('d')
      const today = this.$util.dayjs().startOf('d')
      return expireDate.diff(today, 'd')
    },
    coupon() {
      return this.userHeader.coupon
    }
  }
}
export default couponBanner
</script>

<style lang="scss" scoped>
.c-couponBanner {
  display: flex;
  width: 100%;
  height: 36px;
  background-color: $color-orange-400;
  color: $color-white;
  font-weight: bold;
  font-size: 20px;

  align-items: center;
  justify-content: center;
  &_remainDays {
    margin-right: 8px;
    padding: 0 16px;
    background-color: $color-orange-700;
    font-size: 12px;
  }
  &_remainDaysCount {
    font-weight: bold;
    font-size: 16px;
  }
  &_discountOverview {
    display: flex;

    align-items: center;
    justify-content: center;
  }
  &_text {
    margin-right: 28px;
    font-size: 14px;
  }
  &_discountValue {
    font-weight: bold;
    font-size: 20px;
  }
  &_link {
    @include link-effect;

    color: $color-white;
    text-decoration: underline;
    font-size: 12px;
    line-height: 1em;
  }
  &_chevronRight {
    display: none;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-couponBanner {
    padding: 8px 20px;

    justify-content: space-between;
    &_remainDays {
      margin-right: 8px;
      padding: 0 8px;
    }
    &_text {
      margin-right: 0;
      font-size: 12px;
    }
    &_discountValue {
      font-weight: normal;
      font-size: 16px;
    }
    &_chevronRight {
      display: initial;
    }
  }
}
</style>
