<template>
  <div class="c-serviceDetailRating">
    <div class="c-ratingSummary">
      <div class="c-overall">
        <div class="c-overall_text">
          <div class="c-overall_heading">
            {{ $t('components.contents_rating.comprehensive_evaluation') }}
          </div>
          <div class="c-overall_indicator">
            {{ displayFixed(serviceDetailRatings.overallRating.indicator) }}
          </div>
          <div class="c-overall_count">
            ({{ $util.numberWithDelimiter(serviceOverallRatingCount) }}{{ $t('cases') }})
          </div>
        </div>
        <div class="c-overall_star">
          <CRatingStars
            :size="20"
            :score="serviceDetailRatings.overallRating.indicator"
          />
        </div>
      </div>

      <DRatingChart
        class="c-ratingSummaryChart"
        :rating-stars="serviceDetailRatings.starsList"
        :overall-rating-count="serviceOverallRatingCount"
        is-hoverable
        :selected-star="filterValue"
        :can-animate="false"
        @select="onClickFilterRatingChart"
      />
      <div
        v-if="Object.keys(ratingIndicators).length"
        class="c-privateEvaluation"
      >
        <div class="c-privateEvaluation_heading">
          {{ $t('components.contents_rating.itemized_evaluation') }}
        </div>
        <div class="c-indicators">
          <div
            class="c-indicators_item c-indicator"
            v-if="hasRatingIndicator(ServiceRatingType.RATING_TYPE_DESCRIPTION)"
          >
            <div class="c-indicator_heading">
              {{ $t('components.contents_rating.service_evaluation') }}
              <ClientOnly>
                <RatingTooltip
                  v-if="isPC"
                  :title="ragingTooltips.description.title"
                  class="c-tooltip"
                >
                  <div slot="body">{{ ragingTooltips.description.body }}</div>
                </RatingTooltip>
              </ClientOnly>
            </div>
            <div class="c-indicator_star">
              <CRatingStars
                :size="14"
                :score="ratingIndicators[ServiceRatingType.RATING_TYPE_DESCRIPTION].indicator"
              />
            </div>
          </div>

          <div
            class="c-indicators_item c-indicator"
            v-if="hasRatingIndicator(ServiceRatingType.RATING_TYPE_COMMUNICATION)"
          >
            <div class="c-indicator_heading">
              {{ $t('components.contents_rating.communication') }}
              <ClientOnly>
                <RatingTooltip
                  v-if="isPC"
                  :title="ragingTooltips.communication.title"
                  class="c-tooltip"
                >
                  <div slot="body">{{ ragingTooltips.communication.body }}</div>
                </RatingTooltip>
              </ClientOnly>
            </div>
            <div class="c-indicator_star">
              <CRatingStars
                :size="14"
                :score="ratingIndicators[ServiceRatingType.RATING_TYPE_COMMUNICATION].indicator"
              />
            </div>
          </div>

          <div
            class="c-indicators_item c-indicator"
            v-if="hasRatingIndicator(ServiceRatingType.RATING_TYPE_QUALITY)"
          >
            <div class="c-indicator_heading">
              {{ $t('components.contents_rating.quality') }}
              <ClientOnly>
                <RatingTooltip
                  v-if="isPC"
                  :title="ragingTooltips.quality.title"
                  class="c-tooltip"
                >
                  <div slot="body">{{ ragingTooltips.quality.body }}</div>
                </RatingTooltip>
              </ClientOnly>
            </div>
            <div class="c-indicator_star">
              <CRatingStars
                :size="14"
                :score="ratingIndicators[ServiceRatingType.RATING_TYPE_QUALITY].indicator"
              />
            </div>
          </div>

          <div
            class="c-indicators_item c-indicator"
            v-if="hasRatingIndicator(ServiceRatingType.RATING_TYPE_SCHEDULE)"
          >
            <div class="c-indicator_heading">
              {{ $t('components.contents_rating.schedule') }}
              <ClientOnly>
                <RatingTooltip
                  v-if="isPC"
                  :title="ragingTooltips.schedule.title"
                  class="c-tooltip"
                >
                  <div slot="body">{{ ragingTooltips.schedule.body }}</div>
                </RatingTooltip>
              </ClientOnly>
            </div>
            <div class="c-indicator_star">
              <CRatingStars
                :size="14"
                :score="ratingIndicators[ServiceRatingType.RATING_TYPE_SCHEDULE].indicator"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="c-privateEvaluationPlaceholder"
      ></div>
    </div>
    <div
      id="filterRatingComments"
      class="c-ratingFilter"
    >
      <!-- prettier-ignore -->
      <div>
        <span class="c-ratingFilter_count">{{ $util.numberWithDelimiter(filterRatingCount) }}</span
        >{{ $t('components.contents_rating.case_evaluations') }}
      </div>
      <div class="c-ratingFilter_interface">
        <DSelectCustomizeTrigger
          v-model="filterValue"
          @input="onClickFilterRatingSelectBox"
        >
          <template #trigger>
            <a
              href="javascript:;"
              class="c-ratingFilter_interfaceLink"
            >
              <CoconalaIcon
                name="filter_list"
                size="18px"
                class="c-ratingFilter_interfaceLinkIcon"
              />
              <span>{{ filterName }}</span>
            </a>
          </template>
          <template #option>
            <option
              v-for="n in [5, 4, 3, 2, 1]"
              :value="n"
              :key="`ratingFilter_${n}`"
            >
              {{ $t('components.contents_rating.star') }}{{ n
              }}{{ $t('components.contents_rating.filter_by') }}
            </option>
          </template>
        </DSelectCustomizeTrigger>
        <div
          v-if="filterValue"
          class="c-ratingFilter_clear"
        >
          <a
            href="javascript:;"
            @click="filterValue = 0"
          >
            {{ $t('components.contents_rating.clear') }}
          </a>
        </div>
      </div>
    </div>
    <ul class="c-ratingCommentsList">
      <li
        v-if="!serviceDetailRatings.ratingCommentsList.length"
        class="c-ratingCommentsList_empty"
      >
        <CoconalaIcon
          name="info-circle"
          :size="isPC ? '24px' : '18px'"
          color="ds2Gray200"
          class="c-ratingCommentsList_emptyIcon"
        />
        <span>
          {{ $t('components.contents_rating.not_found') }}
          <br />
          {{ $t('components.contents_rating.chage_condition') }}
        </span>
      </li>
      <li
        v-for="(rating, index) in serviceDetailRatings.ratingCommentsList"
        :key="`rating${rating.id}`"
        class="c-ratingCommentsList_item"
        v-show="isShowDefaultAll || index < defaultDisplayNum"
        v-intersect-on.once="{ threshold: 1.0 }"
        @intersect="callCommentImpression(index)"
      >
        <div class="c-buyer">
          <div class="c-buyer_icon">
            <DUserIcon
              v-if="rating.buyer.id || rating.isFromSystem"
              :file-path="rating.buyer.id ? rating.buyer.icon : ''"
              :user-name="rating.buyer.name"
              :is-from-system="rating.isFromSystem"
              :width="80"
              :height="80"
              size="40px"
            />
            <DUserIcon
              v-else
              :user-name="displayAnonymousPerson(rating.buyer.sex)"
              :width="80"
              :height="80"
              size="40px"
            />
          </div>
          <div
            class="c-buyer_comment"
            v-intersect-translate.once="{ isNoCache: true }"
          >
            <div class="c-buyerCommentRow">
              <CRatingStars
                :size="14"
                :score="rating.overallRate"
              />
              <div class="c-buyerCommentRow_date">
                {{ $util.prettyDateForActivityV2(rating.createdAt.seconds) }}
              </div>
            </div>
            <div class="c-buyerCommentRow_name">
              <!-- idがあるかつisFromSystemでない場合は運営以外のユーザー -->
              <a
                v-if="rating.buyer.id && !rating.isFromSystem"
                class="c-buyerCommentRow_nameText c-buyerCommentRow_nameText-link"
                :href="`/users/${rating.buyer.id}`"
                target="_blank"
                data-ignore-translate
              >
                {{ rating.buyer.name }}
              </a>
              <span
                v-else
                class="c-buyerCommentRow_nameText"
              >
                <!-- ここでidがある場合は運営ユーザー、それ以外は匿名 -->
                {{ rating.buyer.id ? rating.buyer.name : displayAnonymousPerson(rating.buyer.sex) }}
              </span>
              <DLabel
                v-if="rating.isCustomize"
                border-color="Gray200"
                text-color="Gray400"
                class="c-buyerCommentRow_label"
              >
                {{ $t('components.contents_rating.consultation') }}
              </DLabel>
              <DLabel
                v-if="rating.isCancelRating"
                background-color="Gray50"
                text-color="Gray600"
                class="c-buyerCommentRow_label"
              >
                {{ $t('components.contents_rating.mid') }}
              </DLabel>
            </div>
          </div>
        </div>
        <!-- prettier-ignore -->
        <div
          v-if="rating.deleteFlag"
          class="c-buyerCommentBody"
        >{{ $t('components.contents_rating.alert_message') }}<a
            :href="$coconala.helpURL('/articles/360010558453')"
            target="_blank"
            rel="noopener"
            >{{ $t('here') }}</a></div>
        <div
          v-else
          class="c-buyerCommentBody"
        >
          <ContentsRatingComment :comment="rating.comment" />
        </div>
        <RatingCommentReply
          v-if="rating.reply.id"
          :rating-reply="rating.reply"
          class="c-commentReply"
          @read-comment-reply="callCommentReplyImpression"
        />
        <div
          v-if="isMyService"
          class="c-talkroomLink"
        >
          <NuxtLink :to="`/talkrooms/${rating.talkroomId}`">
            <CoconalaIcon
              name="chevron-right"
              size="10px"
            />
            {{ $t('components.contents_rating.talkroom_link') }}
          </NuxtLink>
        </div>
      </li>
    </ul>
    <div
      class="c-loading"
      v-if="isLoadingRatings"
    >
      <DLoading
        :active="isLoadingRatings"
        :is-full-page="false"
      />
    </div>
    <!-- 初回は取得分全部表示せずコンポーネントで表示を制限する -->
    <div
      class="c-readMore"
      v-if="!isShowDefaultAll"
    >
      <a @click="isShowDefaultAll = true">
        {{ $t('components.contents_rating.see_more') }}
        <CoconalaIcon
          name="chevron-down"
          size="10px"
        />
      </a>
    </div>
    <!-- 追加分の取得：可能かどうかは評価数ではなく評価コメントの総数で判定（評価対象外のコメントは表示されるが、評価数には含まれない為） -->
    <div
      class="c-readMore"
      v-else-if="serviceDetailRatings.ratingCommentsList.length < serviceDetailRatings.totalCount"
    >
      <a @click="getMoreRatings(serviceDetailRatings.page)">
        {{ $t('components.contents_rating.see_more') }}
        <CoconalaIcon
          name="chevron-down"
          size="10px"
        />
      </a>
    </div>
    <DTranslatedByGoogle v-if="$translate.isTranslatableTarget()" />
  </div>
</template>
<script>
const SEX_FEMALE = 'F'
const SEX_MALE = 'M'
const DEFAULT_DISPLAY_NUM = 10
const DEFAULT_DISPLAY_NUM_LOGGED_IN = 4

import CRatingStars from '~/components/atoms/CRatingStars'
import DLoading from '~/components/atoms/DS2/DLoading'
import DRatingChart from '~/components/molecules/DS2/DRatingChart'
import DSelectCustomizeTrigger from '~/components/molecules/DS2/DSelectCustomizeTrigger'
import DUserIcon from '~/components/atoms/DS2/DUserIcon'
import { ServiceRatingType } from '~/stub/domain/enumeration/service_rating_type_pb'

import { mapGetters } from 'vuex'

export default {
  name: 'ContentsRating',
  components: {
    CRatingStars,
    DLoading,
    DRatingChart,
    DSelectCustomizeTrigger,
    DUserIcon,
    DLabel: () => import('~/components/atoms/DS2/DLabel'),
    RatingCommentReply: () => import('./RatingCommentReply'),
    RatingTooltip: () => import('./RatingTooltip'),
    DTranslatedByGoogle: () => import('~/components/molecules/DS2/DTranslatedByGoogle'),
    ContentsRatingComment: () => import('./ContentsRatingComment.vue')
  },
  inject: {
    $serviceOverallRatingCountReactive: { default: () => 0 },
    impressionLogRatingServiceDetail: { default: () => ({}) }
  },
  props: {
    serviceDetailRatings: {
      type: Object,
      required: true
    },
    ratingIndicators: {
      type: Object,
      required: true
    },
    isMyService: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultDisplayNum: DEFAULT_DISPLAY_NUM,
      isShowDefaultAll: true,
      isLoadingRatings: false,
      sexFemale: SEX_FEMALE,
      sexMale: SEX_MALE,
      filterValue: 0,
      ServiceRatingType,
      readCommentImpressionOnce: false,
      readCommentReplyImpressionOnce: false
    }
  },
  computed: {
    ...mapGetters('ui', ['isPC']),
    ...mapGetters('auth', ['isLoggedIn']),
    ragingTooltips() {
      return {
        description: {
          title: this.$t('components.contents_rating.annotation_service_title'),
          body: this.$t('components.contents_rating.annotation_service_body')
        },
        communication: {
          title: this.$t('components.contents_rating.annotation_communication_title'),
          body: this.$t('components.contents_rating.annotation_communication_body')
        },
        quality: {
          title: this.$t('components.contents_rating.annotation_quality_title'),
          body: this.$t('components.contents_rating.annotation_quality_body')
        },
        schedule: {
          title: this.$t('components.contents_rating.annotation_schedule_title'),
          body: this.$t('components.contents_rating.annotation_schedule_body')
        }
      }
    },
    filterName() {
      if (this.filterValue === 0) {
        return this.$t('components.contents_rating.filter_by_rating')
      }

      return `${this.$t('components.contents_rating.star')}${this.filterValue}${this.$t(
        'components.contents_rating.filter_by'
      )}`
    },
    filterRatingCount() {
      if (this.filterValue === 0) return this.serviceOverallRatingCount
      const starItem = this.serviceDetailRatings.starsList.find(
        item => item.star === this.filterValue
      )
      return starItem ? starItem.count : 0
    },
    serviceOverallRatingCount() {
      return this.$serviceOverallRatingCountReactive()
    }
  },
  mounted() {
    if (this.isLoggedIn) {
      this.defaultDisplayNum = DEFAULT_DISPLAY_NUM_LOGGED_IN
    }
    this.isShowDefaultAll =
      this.serviceDetailRatings.ratingCommentsList.length <= this.defaultDisplayNum
  },
  watch: {
    async filterValue(value) {
      const params = this.$route.params
      this.isLoadingRatings = true
      const serviceContentsMenuPc = document.getElementsByClassName('c-serviceContentsMenuPc')
      const serviceContentsMenuPcHeight = serviceContentsMenuPc.length
        ? serviceContentsMenuPc[0].clientHeight
        : 0
      this.$scrollTo('#filterRatingComments', { offset: -serviceContentsMenuPcHeight })
      try {
        await this.$store.dispatch('pages/services/serviceDetailRatings/getRatingComments', {
          params,
          star: value
        })
        this.isShowDefaultAll = true
      } catch ({ grpcCode, message }) {
        this.$notify({
          group: 'flash',
          title: 'エラーが発生しました',
          text: 'しばらく時間をおいてからやり直してください',
          type: 'error',
          max: 1
        })
      }
      this.isLoadingRatings = false
    }
  },
  methods: {
    hasRatingIndicator(ratingType) {
      return this.ratingIndicators[ratingType] && this.ratingIndicators[ratingType].indicator >= 0
    },
    displayFixed(value, digit = 1) {
      return value.toFixed(digit)
    },
    displayAnonymousPerson(sex) {
      if (sex === this.sexFemale) {
        return this.$t('female')
      }

      if (sex === this.sexMale) {
        return this.$t('male')
      }

      // その他や未入力の場合
      return this.$t('anonymous')
    },
    onClickFilterRatingChart(star) {
      this.filterValue = star

      this.addLogImpression('view_service_evaluation', {
        ...this.impressionLogRatingServiceDetail,
        star_rating_indicator: this.filterValue,
        star_rating_count: this.filterRatingCount,
        click_point: 'search_graph'
      })
    },
    callCommentImpression(index) {
      if (this.readCommentImpressionOnce || index !== 0) return
      this.addLogImpression('view_service_evaluation', {
        ...this.impressionLogRatingServiceDetail,
        click_point: null
      })
      this.readCommentImpressionOnce = true
    },
    callCommentReplyImpression() {
      if (this.readCommentReplyImpressionOnce) return
      this.addLogImpression('view_service_evaluation_provider', {
        ...this.impressionLogRatingServiceDetail,
        star_rating_indicator: this.filterValue === 0 ? null : this.filterValue,
        star_rating_count: this.filterValue === 0 ? null : this.filterRatingCount
      })
      this.readCommentReplyImpressionOnce = true
    },
    onClickFilterRatingSelectBox() {
      this.addLogImpression('view_service_evaluation', {
        ...this.impressionLogRatingServiceDetail,
        star_rating_indicator: this.filterValue,
        star_rating_count: this.filterRatingCount,
        click_point: 'search_text'
      })
    },
    async addLogImpression(action, detailObject) {
      try {
        await this.$store.dispatch('big_query/log_impression/addLogImpression', {
          action,
          detailJson: JSON.stringify(detailObject)
        })
      } catch (e) {
        this.$sentry.captureException(e)
      }
    },
    async getMoreRatings(page) {
      const getPage = page + 1
      const params = this.$route.params
      this.isLoadingRatings = true
      try {
        await this.$store.dispatch('pages/services/serviceDetailRatings/getRatingComments', {
          params,
          page: getPage,
          star: this.filterValue
        })
      } catch ({ grpcCode, message }) {
        this.$notify({
          group: 'flash',
          title: 'エラーが発生しました',
          text: 'しばらく時間をおいてからやり直してください',
          type: 'error',
          max: 1
        })
      }
      this.isLoadingRatings = false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-serviceDetailRating {
  a {
    @include link-effect;
  }
}

.c-ratingSummary {
  display: flex;
}

.c-overall {
  margin-top: 16px;
  text-align: center;

  &_heading {
    font-size: 16px;
    line-height: 1;
  }

  &_indicator {
    margin: 4px 0;
    font-size: 40px;
    line-height: 1.2;
  }

  &_count {
    display: none;
  }

  &_star {
    line-height: 1;
  }
}

.c-ratingSummaryChart {
  margin-left: 40px;
  width: 100%;
}

.c-privateEvaluation {
  margin: 4px 0 0 40px;
  padding-left: 30px;
  min-width: 328px;
  border-left: 1px solid $ds2-color-gray-50;

  &_heading {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.5;
  }
}

.c-privateEvaluationPlaceholder {
  min-width: 368px;
}

.c-indicators {
  display: flex;
  margin-top: 6px;

  flex-wrap: wrap;

  &_item {
    padding: 2px 0;

    flex-basis: 300px;
  }
}

.c-indicator {
  display: flex;

  align-items: center;
  justify-content: space-between;

  &_heading {
    font-size: 12px;
    line-height: 1.5;

    ::v-deep .coconala-icon {
      vertical-align: -2px;
    }
  }

  &_star {
    ::v-deep .c-ratingStars {
      vertical-align: -2px;
    }
  }
}

.c-tooltip {
  display: inline;
}

.c-ratingFilter {
  display: flex;
  margin-top: 4px;
  padding: 0 8px;
  height: 40px;
  white-space: nowrap;
  font-size: 12px;

  align-items: center;

  &_count {
    font-weight: bold;
    font-size: 14px;
  }

  &_interface {
    display: flex;
    margin-left: 32px;
  }

  &_interfaceLink {
    display: flex;
  }

  &_interfaceLinkIcon {
    margin-right: 4px;
  }

  &_clear {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid $ds2-color-gray-50;

    a {
      color: $ds2-color-gray-700;
    }
  }
}

.c-buyer {
  display: flex;

  &_icon {
    flex: 0 0 40px;
    overflow: hidden;
    margin-right: 16px;
    width: 40px;
    height: 40px;
  }

  &_comment {
    flex: 1 1 auto;
  }
}

.c-ratingCommentsList {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid $ds2-color-gray-200;

  &_empty {
    display: flex;
    margin: 40px 0;
    text-align: center;
    font-size: 18px;

    justify-content: center;
  }

  &_emptyIcon {
    margin: 2px 4px 0 0;
  }

  &_item {
    padding: 16px 0;
    border-bottom: 1px solid $ds2-color-gray-50;
  }
}

.c-buyerCommentRow {
  display: flex;

  justify-content: space-between;

  &_name {
    margin-top: 8px;
    color: $ds2-color-gray-600;
    font-size: 12px;
    line-height: 1.2;
  }

  &_nameText,
  &_label {
    margin-right: 4px;
  }

  &_nameText {
    color: $ds2-color-gray-600;
    font-size: 12px;
    line-height: 1.2;
    &-link {
      text-decoration: underline;
    }
  }

  &_date {
    color: $ds2-color-gray-400;
    text-align: right;
    font-size: 11px;
    line-height: 1.2;
  }
}

.c-buyerCommentBody {
  margin: 8px 0 0 56px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 13px;
  line-height: 1.5;
}

.c-commentReply {
  margin: 8px 0 0 56px;
}

.c-readMore {
  margin-top: 12px;
  font-size: 12px;
  line-height: 1.5;

  ::v-deep .coconala-icon {
    vertical-align: -1px;

    &::before {
      display: inline-block;
      transform: scale(0.8);
    }
  }
}

.c-talkroomLink {
  margin-top: 8px;
  margin-left: 56px;
  font-size: 13px;
  line-height: 1.5;

  ::v-deep .coconala-icon {
    vertical-align: 0;

    &::before {
      display: inline-block;
      transform: scale(0.8);
    }
  }
}

.c-loading {
  position: relative;
  display: flex;
  padding: 12px;
  width: 100%;
  height: 80px;
  font-size: 14px;

  align-items: center;
  justify-content: center;
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-serviceDetailRating {
    ::v-deep {
      .d-translatedByGoogle {
        margin: 0;
        padding: 8px;
        background-color: $ds2-color-gray-25;
      }
    }
  }
  .c-ratingSummary {
    display: block;
    padding: 16px 12px;
  }

  .c-overall {
    display: flex;
    margin-top: 0;
    text-align: left;
    font-weight: bold;

    justify-content: space-between;

    &_text {
      display: flex;

      align-items: center;
    }

    &_heading {
      font-size: 12px;
      line-height: 1.2;
    }

    &_indicator {
      margin: 0 4px 0 8px;
      font-size: 18px;
      line-height: 1;
    }

    &_count {
      display: block;
    }
  }

  .c-ratingSummaryChart {
    margin-top: 8px;
    margin-left: 0;
  }

  .c-privateEvaluation {
    margin: 8px 0 0;
    padding: 12px 0 0;
    min-width: auto;
    border-top: 1px solid $ds2-color-gray-50;
    border-left: 0;

    &_heading {
      font-size: 12px;
      line-height: 1.3;
    }
  }

  .c-indicators {
    margin-top: 10px;

    &_item {
      flex-basis: 100%;
    }
  }

  .c-ratingFilter {
    margin-top: 0;
    padding: 0 12px;
    border-top: 1px solid $ds2-color-gray-200;

    justify-content: space-between;
  }

  .c-ratingCommentsList {
    margin-top: 0;
    padding-top: 0;
    border-top: 1px solid $ds2-color-gray-50;

    &_empty {
      font-size: 14px;
    }

    &_item {
      padding: 12px;
    }
  }

  .c-buyer {
    margin-bottom: 8px;

    &_icon {
      margin-right: 8px;
    }
  }

  .c-buyerCommentRow {
    &_name {
      line-height: 1.3;
    }

    &_date {
      font-size: 10px;
      line-height: 1.3;
    }
  }

  .c-buyerCommentBody {
    margin-left: 0;
    font-size: 14px;
    line-height: 1.3;
  }

  .c-commentReply {
    margin-left: 0;
  }

  .c-talkroomLink {
    margin-left: 0;
  }

  .c-readMore {
    margin-top: 0;
    padding: 8px 12px;
  }
}
</style>
