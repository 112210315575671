<template>
  <i
    class="c-ratingStars"
    :data-score="roundedScore"
    :data-size="size"
  ></i>
</template>
<script>
export default {
  name: 'CRatingStars',
  props: {
    score: {
      type: Number
    },
    size: {
      type: Number
    }
  },
  computed: {
    roundedScore() {
      // 評価を0.5刻みに丸める
      return Math.round(this.score * 2) / 2
    }
  }
}
</script>
<style lang="scss" scoped>
// TODO: 本当はここに書きたいが、_coconala-icons.scssの変数がグローバルで使えないため、
//       とりあえず_base.scssに書いてある
</style>
