<template>
  <social-sharing
    :class="{ '-is-monochromatic': isMonochromatic }"
    :url="getUrl()"
    :title="title"
    :hashtags="hashtags"
    :is-monochromatic="isMonochromatic"
    @clip="doClip"
    inline-template
  >
    <div class="c-shareLinks">
      <ul class="c-shareLinks_list">
        <li class="c-shareLinks_listItem -facebook">
          <network network="facebook">
            <CoconalaIcon name="facebook-brands" />
          </network>
        </li>
        <li class="c-shareLinks_listItem -twitter">
          <network network="twitter">
            <CoconalaIcon name="x-circle" />
          </network>
        </li>
        <li
          class="c-shareLinks_listItem -share"
          @click="$emit('clip')"
        >
          <CoconalaIcon name="share" />
        </li>
      </ul>
    </div>
  </social-sharing>
</template>
<script>
export default {
  name: 'ShareLinks',
  props: {
    path: {
      type: String
    },
    title: {
      type: String
    },
    hashtags: {
      type: String,
      default: ''
    },
    isMonochromatic: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    doClip() {
      this.$util.copy(this.getUrl())
      this.$toast.open({
        message: this.$t('components.share_links.copy_message'),
        type: 'success'
      })
    },
    getUrl() {
      return this.path ? `${location.origin}${this.path}` : location.href
    }
  }
}
</script>
<style lang="scss">
.c-shareLinks {
  border-radius: 5px;

  &_list {
    display: flex;

    justify-content: center;
  }

  &_listItem {
    @include link-effect;

    cursor: pointer;

    & + & {
      margin-left: 20px;
    }

    &.-facebook {
      i {
        color: $color-sns-facebook;
        font-size: 32px;
      }
      span {
        outline: none;
      }
    }

    &.-twitter {
      i {
        color: $color-sns-twitter;
        font-size: 32px;
      }
      span {
        outline: none;
      }
    }

    &.-share {
      display: flex;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $ds2-color-gray-400;

      align-items: center;
      justify-content: center;

      i {
        margin-bottom: -2px;
        color: $color-white;
        font-size: 14px;
      }
    }
  }

  &.-is-monochromatic {
    .c-shareLinks_listItem {
      &.-facebook {
        i {
          color: $ds2-color-font-base;
        }
      }

      &.-twitter {
        i {
          color: $ds2-color-font-base;
        }
      }

      &.-share {
        background-color: $ds2-color-font-base;
      }
    }
  }
}
</style>
