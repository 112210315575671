import { mapGetters, mapState } from 'vuex'
import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'
import { grpc } from '@improbable-eng/grpc-web'

// チャット表示するページは下記に記載する
const DISPLAY_CHAT_PAGE_NAME = Object.freeze([
  'index',
  'mypage-dashboard',
  'search',
  'categories-categoryId',
  'services-id',
  'users',
  'users-search',
  'users-id',
  'customize-requests-add-userId',
  'customize-requests-add-userId-serviceId',
  'enterprise-orders',
  'enterprise-members',
  'enterprise-invitations',
  'business-projects',
  'business-projects-id',
  'business-settings-corporation'
])

const DISPLAY_CHAT_PAGE_NAME_FOR_BUSINESS_ACCOUNT = Object.freeze([
  'message',
  'talkrooms-talkroomId',
  'mypage-orders-required',
  'mypage-orders-requests',
  'mypage-orders-open',
  'mypage-orders-closed',
  'business-settings-invoice'
])

export default {
  computed: {
    ...mapState('auth/user', ['isBusiness']),
    ...mapGetters('ui', ['hasDeviceJudged', 'isTalkroomPC']),
    ...mapGetters('auth', ['isLoggedIn', 'isFromCoconalaApp'])
  },
  async mounted() {
    /**
     * 通常アカ、ビジアカともに基本非表示（表示画面を個別に対応）
     */
    if (!this.isLoggedIn || this.isFromCoconalaApp) return

    //法人機能利用オンユーザーにだけ表示させる
    if (!this.isBusiness && DISPLAY_CHAT_PAGE_NAME_FOR_BUSINESS_ACCOUNT.includes(this.$route.name))
      return

    const verticalSize = await this.getMobileVertical()

    if (typeof zE === 'function') {
      // 既にscriptが読み込まれている場合は配置設定を更新して再表示
      zE('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            vertical: verticalSize,
            mobile: {
              vertical: verticalSize
            }
          }
        }
      })
      zE('webWidget', 'show')
      return
    }

    try {
      // チャットサポート対象ユーザかどうかの判定
      const { UserAccessor } = await import('~/api/accessors/profile/user-accessor')
      const { canUseChatSupport } = await new UserAccessor(this.$store.state).getCanUseChatSupport()
      if (!canUseChatSupport) return
    } catch (e) {
      if (![grpc.Code.Unknown, grpc.Code.Unauthenticated].includes(e.grpcCode)) {
        this.$sentry.captureException(e)
      }
      return
    }

    const script = document.createElement('script')
    script.src = process.env.config.zendeskWidgetScript
    script.id = 'ze-snippet'
    document.head.appendChild(script)

    script.onload = () => {
      window.zESettings = {
        webWidget: {
          color: {
            // ここで設定しない場合、zendesk上での設定が適用される
            theme: '#0098ff' // 通常アカ：ds2-color-brand-secondary-500
          },
          offset: {
            vertical: verticalSize,
            mobile: {
              vertical: verticalSize
            }
          },
          authenticate: {
            chat: {
              // 認証されたアカウントの名前・メールアドレスがフォームに入力され送信される
              jwtFn: async callback => {
                const [requestModule, methodModule] = await Promise.all([
                  import('~/stub/apigateway/business/business_chat_pb'),
                  import('~/stub/apigateway/business/business_chat_pb_service')
                ])
                const metadata = commonMetaData(this.$store.state)
                const request = new requestModule.GetZendeskChatJwtRequest()
                try {
                  const response = await grpcClient({
                    method: methodModule.BusinessChatService.GetZendeskChatJwt,
                    request,
                    metadata
                  })
                  callback(response.chatJwt)
                } catch (err) {
                  // NOTE: jwt取得のエラーは握りつぶしてSentryに送信
                  this.$sentry.captureException(err)
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    async getMobileVertical() {
      // SP,TBで個別にチャットボタンの配置調整が必要な画面の配置位置取得（marginを除いて画面下部からの高さ）
      switch (this.$route.name) {
        case 'services-id': {
          await this.$util.waitUntil(() => this.hasDeviceJudged) // デバイスでDOM表示切り替えしている箇所がある為、判定を待つ
          const serviceDetailPurchaseSp = document.getElementsByClassName(
            'c-serviceDetailPurchaseSp'
          )
          const serviceDetailPurchaseSpHeight = serviceDetailPurchaseSp.length
            ? serviceDetailPurchaseSp[0].clientHeight
            : 0
          const spTabButton = document.getElementsByClassName('c-spTab_button')
          const spTabButtonHeight = spTabButton.length ? spTabButton[0].clientHeight : 0
          return `${serviceDetailPurchaseSpHeight + spTabButtonHeight}px`
        }
        case 'users':
        case 'users-search': {
          const verticalDrawer = document.getElementsByClassName('c-verticalDrawer')
          const verticalDrawerHeight = verticalDrawer.length ? verticalDrawer[0].clientHeight : 0
          return `${verticalDrawerHeight}px`
        }
        case 'users-id': {
          const conversionButton = document.getElementsByClassName(
            'd-profileConversionButton_inner'
          )
          if (!conversionButton.length) return '0px'
          const conversionButtonStyle = window.getComputedStyle(conversionButton[0])
          const conversionButtonPosition = conversionButtonStyle.getPropertyValue('position')
          const conversionButtonpHeight =
            conversionButtonPosition === 'fixed' ? conversionButton[0].clientHeight : 0
          return `${conversionButtonpHeight}px`
        }
        case 'customize-requests-add-userId':
        case 'customize-requests-add-userId-serviceId': {
          await this.$util.waitUntil(() => this.hasDeviceJudged) // デバイスでDOM表示切り替えしている箇所がある為、判定を待つ
          const customizeRequestAddFooter = document.getElementsByClassName(
            'c-customizeRequestAddFooter_fixedButton'
          )
          const customizeRequestAddFooterHeight = customizeRequestAddFooter.length
            ? customizeRequestAddFooter[0].clientHeight
            : 0
          return `${customizeRequestAddFooterHeight}px`
        }
        case 'talkrooms-talkroomId': {
          await this.$util.waitUntil(() => this.hasDeviceJudged)
          const messageForm = document.getElementsByClassName('d-messageForm')
          const messageFormHeight = messageForm.length ? messageForm[0].clientHeight : 0
          return this.isTalkroomPC ? '0px' : `${messageFormHeight}px`
        }
        default:
          return '0px'
      }
    }
  },
  beforeDestroy() {
    if (!this.isLoggedIn || this.isFromCoconalaApp) return

    // CSR遷移時に表示したままになるよう遷移先が表示ページの場合は一旦非表示にしない
    // beforeDestroy時のthis.$routeは遷移先
    if (
      !(
        DISPLAY_CHAT_PAGE_NAME.includes(this.$route.name) ||
        (DISPLAY_CHAT_PAGE_NAME_FOR_BUSINESS_ACCOUNT.includes(this.$route.name) && this.isBusiness)
      ) &&
      typeof zE === 'function'
    ) {
      zE('webWidget', 'hide')
    }
  }
}
