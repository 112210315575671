// source: domain/enumeration/service_rating_type.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ServiceRatingType', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ServiceRatingType = {
  RATING_TYPE_UNSPECIFIED: 0,
  RATING_TYPE_DESCRIPTION: 1,
  RATING_TYPE_COMMUNICATION: 2,
  RATING_TYPE_QUALITY: 3,
  RATING_TYPE_SCHEDULE: 4
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
