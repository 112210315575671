
import { defineComponent, PropType } from '@nuxtjs/composition-api'

type BAR_COLORS = 'ds2Orange'

export default defineComponent({
  name: 'DHorizontalBar',
  props: {
    rate: {
      // バーの割合（1を100%とする）
      type: Number,
      default: 0
    },
    color: {
      type: String as PropType<BAR_COLORS>,
      default: 'ds2Orange'
    },
    canAnimate: {
      type: Boolean,
      default: true
    },
    isOpened: {
      // アニメーションする場合は、これをtrueにしたタイミングでバーが0からrateの位置までアニメーションで伸びる
      type: Boolean,
      default: false
    },
    delaySeconds: {
      // アニメーションする場合に開始を遅延する秒数
      type: Number,
      default: 0
    }
  },
  computed: {
    isActive() {
      return this.canAnimate ? this.isOpened : true
    }
  }
})
