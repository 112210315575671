<template>
  <div class="c-contentsPurchaseSp">
    <ContentsPurchaseSpPurchaseButton
      v-if="!isCustomizeRequired"
      :service-detail="serviceDetail"
      :total-price="totalPrice"
    />
    <div
      class="c-serviceReservation"
      v-if="isServiceReservation"
    >
      <CButtonV2
        color="secondary"
        size="s"
        width="100%"
        @click="addServiceReservation($event)"
        v-if="!serviceDetail.buyer.isReservation && !setReservation"
        >{{ $t('components.service_detail_purchase.receive_notify') }}</CButtonV2
      >
      <CButtonV2
        color="secondary"
        size="s"
        width="100%"
        disabled
        v-else
        >{{ $t('components.service_detail_purchase.notify') }}
      </CButtonV2>
    </div>
    <div
      class="c-purchaseSpCustomize"
      v-if="isCustomize"
    >
      <CButtonV2
        color="secondary"
        size="ms"
        width="100%"
        :href="urlCustomize"
        need-logged-in
      >
        {{ $t('components.service_detail_purchase.consultation') }}
      </CButtonV2>
    </div>
    <div
      class="c-purchaseSpButtons"
      v-if="!isMyService"
    >
      <div class="c-purchaseSpButtons_fav">
        <FavButtonV2
          :is-favorited="isFavorited"
          :fav-count="favCount"
          :service-id="serviceId"
          is-always-show
          type="TextCount"
        />
      </div>
      <div class="c-purchaseSpButtons_faq">
        <CButtonV2
          color="thirdly"
          size="ms"
          width="100%"
          :href="urlDm"
          need-logged-in
        >
          {{ $t('components.service_detail_provider.ask_the_exhibitor') }}
        </CButtonV2>
      </div>
    </div>
  </div>
</template>
<script>
import FavButtonV2 from '~/components/molecules/buttons/FavButtonV2'
import ContentsPurchaseSpPurchaseButton from './ContentsPurchaseSpPurchaseButton'

// vuex
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ContentsPurchaseSp',
  components: {
    FavButtonV2,
    ContentsPurchaseSpPurchaseButton
  },
  props: {
    serviceDetail: {
      type: Object,
      required: true
    },
    totalPrice: {
      type: Number,
      required: true
    },
    urlDm: {
      type: String,
      required: true
    },
    urlCustomize: {
      type: String,
      required: true
    },
    isCustomize: {
      type: Boolean,
      required: true
    },
    isMyService: {
      type: Boolean,
      default: false
    },
    isServiceReservation: {
      type: Boolean,
      required: true
    },
    serviceId: {
      type: Number,
      default: null
    },
    favCount: {
      type: Number,
      default: 0
    },
    isFavorited: {
      type: Boolean,
      default: false
    },
    setReservation: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('constants', ['serviceClass']),
    isCustomizeRequired() {
      return this.isCustomize && this.serviceDetail.serviceDetail.customizeRequiredFlag
    }
  },
  methods: {
    ...mapActions('ui', ['showLoginModal']),
    async addServiceReservation(e) {
      if (!this.isLoggedIn) {
        e.preventDefault()
        this.showLoginModal()
        return
      }
      const serviceId = this.$store.state.route.params.id
      try {
        const serviceReservation = await this.$store.dispatch(
          'pages/services/serviceDetail/addServiceReservation',
          serviceId
        )
        if (this.$util.hasPath(serviceReservation, 'message') && serviceReservation.message) {
          this.$notify({
            group: 'flash',
            title: serviceReservation.message,
            type: 'success'
          })
        } else {
          this.$emit('emitReservation', true)
        }
      } catch (err) {
        this.$notify({
          group: 'flash',
          title: err.message,
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-contentsPurchaseSp {
    padding: 16px 12px;
    border-bottom: 4px solid $ds2-color-gray-25;
  }

  .c-serviceReservation {
    margin-bottom: 12px;
  }

  .c-purchaseSpCustomize {
    margin-bottom: 12px;
  }

  .c-purchaseSpButtons {
    display: flex;
    justify-content: space-between;
    height: 32px;

    &_fav,
    &_faq {
      flex-basis: 48%;
    }
  }
}
</style>
