<template>
  <h2
    class="c-HeadingSecond"
    :class="{ 'c-HeadingSecond-withBorder': hasBorder }"
    v-intersect-translate.once
  >
    {{ heading }}
  </h2>
</template>
<script>
export default {
  name: 'HeadingSecond',
  props: {
    heading: {
      type: String
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.c-HeadingSecond {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 20px;
  line-height: 1;

  &-withBorder {
    padding-left: 12px;
    border-left: 7px solid $ds2-color-brand-primary;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-HeadingSecond {
    margin-bottom: 0;
    padding: 24px 12px 8px;
    border: 0;
    background: $ds2-color-gray-25;
    font-weight: normal;
    font-size: 14px;
  }
}
</style>
