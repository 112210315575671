export default {
  methods: {
    genMetaTitle(text, suffix = 'ココナラ') {
      return `${text} | ${suffix}`
    },
    genMetaDescription(
      text = '',
      defaultText = 'ココナラビジネスは、あらゆるジャンルのプロフェッショナルにオンラインで簡単に依頼ができるスキルマーケットです。WEBサイト制作や動画編集、マーケティングやビジネス相談などあなたの依頼内容にぴったりのプロフェッショナルが見つかります。'
    ) {
      const content = text || defaultText
      return {
        hid: 'description',
        name: 'description',
        content: this.$util.truncate(content, 300)
      }
    },
    genMetaKeywords(
      text = '',
      defaultText = 'ビジネス,スキルマーケット,スキルシェア,マーケットプレイス,ココナラ,coconala,ここなら'
    ) {
      const content = text || defaultText
      return {
        hid: 'keywords',
        name: 'keywords',
        content: this.$util.truncate(content, 300)
      }
    },
    genMetaRobots(text = '', defaultText = 'noindex,nofollow') {
      const content = text || defaultText
      return {
        hid: 'robots',
        name: 'robots',
        content: this.$util.truncate(content, 300)
      }
    },
    genMetaType(text = '', defaultText = 'article') {
      const content = text || defaultText
      return content
    },
    genMetaSiteName(text = '', defaultText = 'ココナラビジネス') {
      const content = text || defaultText
      return content
    },
    genOGP(
      url = null,
      type = null,
      title = null,
      description = null,
      siteName = null,
      image = null
    ) {
      const props = []
      if (url) {
        // 不要なクエリを除く
        url = url.replace(/\?.*$/, '')
        url = `https://coconala.com${url}`
        props.push({ hid: 'og:url', property: 'og:url', content: url })
      }
      if (type) props.push({ hid: 'og:type', property: 'og:type', content: type })
      if (title) props.push({ hid: 'og:title', property: 'og:title', content: title })
      if (description)
        props.push({
          hid: 'og:description',
          property: 'og:description',
          content: description
        })
      if (siteName) props.push({ hid: 'og:site_name', property: 'og:site_name', content: siteName })
      props.push({
        hid: 'og:image',
        property: 'og:image',
        content:
          image || this.$coconala.fileURL('/staticfiles/images/pages/business/default_ogp.png')
      })

      return props
    },
    // ビジネスのmetaタグまとめて生成
    genMeta(title, options = {}) {
      const metaTitle = this.genMetaTitle(title)
      const metaDescription = this.genMetaDescription(options.description || '')
      const keywords = this.genMetaKeywords(options.keywords || '')
      const robots = this.genMetaRobots(options.robots || '')
      const ogpUrl = this.$coconala.url(options.url || this.$route.path)
      const type = this.genMetaType(options.type || '')
      const siteName = this.genMetaSiteName(options.siteName || '')
      const image = options.image || null
      const script = options.script || []
      if (options.isShowZendesk && typeof zE !== 'function') {
        script.push({
          src: process.env.config.zendeskWidgetScript,
          id: 'ze-snippet'
        })
      }
      return {
        title: metaTitle,
        meta: [
          metaDescription,
          keywords,
          robots,
          ...this.genOGP(ogpUrl, type, metaTitle, metaDescription.content, siteName, image)
        ],
        script
      }
    },
    addQueryToUrlIfBusinessPreview(url) {
      if (!this.$route.query.is_business) {
        // ビジネスプレビューモードでないときは何もしない
        return url
      }

      // ビジネスプレビューモード時のリンクにクエリを追加する
      return url + (url.indexOf('?') === -1 ? '?is_business=1' : '&is_business=1')
    }
  }
}
