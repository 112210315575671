
import { defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'
import DHorizontalBar from '~/components/atoms/DS2/DHorizontalBar'
import { Star } from '~/stub/gw/service/service_pb'
import { Stripped } from '~/grpc/grpc-util'

type StarType = Stripped<Star.AsObject>

export default defineComponent({
  name: 'DRatingChart',
  components: { DHorizontalBar },
  props: {
    ratingStars: {
      type: Array as PropType<StarType[]>,
      required: true
    },
    overallRatingCount: {
      type: Number,
      required: true
    },
    isHoverable: {
      type: Boolean,
      default: false
    },
    selectedStar: {
      type: Number,
      default: 0
    },
    canAnimate: {
      type: Boolean,
      default: true
    },
    isOpened: {
      // アニメーションする場合は、これをtrueにしたタイミングで上のバーから順に0からrateの位置までアニメーションで伸びる
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { isHoverable, selectedStar } = toRefs(props)
    const ratingChartItemClass = (ratingStar: StarType) => {
      if (ratingStar.star === selectedStar.value) return 'd-ratingChartItem-selected'
      if (isHoverable.value && ratingStar.count) return 'd-ratingChartItem-hoverable'
      return ''
    }
    const select = (ratingStar: StarType) => {
      if (!ratingStar.count || ratingStar.star === selectedStar.value) return
      emit('select', ratingStar.star)
    }
    return {
      ratingChartItemClass,
      select
    }
  }
})
