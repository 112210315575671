<template>
  <div class="c-divinationIcon">
    <BalloonDropdown
      v-if="isPC && isHoverable"
      position="bottom-right"
      :triggers="['hover']"
      width="300px"
    >
      <a
        class="c-divinationIcon_image"
        href="javascript:;"
        slot="trigger"
      >
        <CoconalaIcon
          v-if="isQuestion"
          name="question-circle-o"
        />
        <img
          v-else-if="isOutlined"
          :src="$coconala.fileURL('/staticfiles/images/certification/divination/icon-outline.svg')"
          alt="占い"
          loading="lazy"
          decoding="async"
          :style="styles"
        />
        <img
          v-else
          :src="$coconala.fileURL('/staticfiles/images/certification/divination/icon.svg')"
          alt="占い"
          loading="lazy"
          decoding="async"
          :style="styles"
        />
      </a>
      <ClientOnly>
        <div
          class="c-divinationIcon_help"
          @click.stop.prevent
          v-intersect-translate.once
        >
          <div class="c-divinationIcon_helpText">
            <span>
              占い領域での高い実績と鑑定のプロセスなどを満たした運営審査済のサービスに与えられるラベルです
            </span>
            <br />
            <a @click="openLink">
              <span>占い認定について</span>
              <CoconalaIcon name="chevron-right" />
            </a>
          </div>
          <div class="c-divinationIcon_helpList">
            <ul>
              <li>
                <CoconalaIcon name="check" />
                高い信頼性
              </li>
              <li>
                <CoconalaIcon name="check" />
                丁寧なやりとり
              </li>
              <li>
                <CoconalaIcon name="check" />
                本人確認済
              </li>
            </ul>
          </div>
        </div>
      </ClientOnly>
    </BalloonDropdown>
    <div v-else>
      <img
        v-if="isOutlined"
        :src="$coconala.fileURL('/staticfiles/images/certification/divination/icon-outline.svg')"
        alt="占い"
        loading="lazy"
        :style="styles"
      />
      <img
        v-else
        :src="$coconala.fileURL('/staticfiles/images/certification/divination/icon.svg')"
        alt="占い"
        loading="lazy"
        :style="styles"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import BalloonDropdown from '~/components/molecules/BalloonDropdown'

export default {
  name: 'DivinationIcon',
  props: {
    width: {
      type: String,
      default: 'auto'
    },
    isOutlined: {
      type: Boolean,
      default: false
    },
    isQuestion: {
      type: Boolean,
      default: false
    },
    isHoverable: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BalloonDropdown
  },
  computed: {
    ...mapGetters('ui', ['isPC']),
    styles() {
      return {
        width: this.width
      }
    }
  },
  methods: {
    openLink() {
      open(this.$coconala.helpURL('/articles/4408460869785'), '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.c-divinationIcon {
  &_help {
    display: flex;
    padding: 8px 12px;
    text-align: left;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.6;
  }

  &_helpText {
    margin-right: 12px;

    flex-basis: 150px;

    a {
      display: inline-block;
      margin-top: 8px;
      color: $color-white;
    }
  }

  &_helpList {
    ul {
      li {
        margin-bottom: 4px;
        font-size: 11px;
      }
    }
  }

  &_image {
    color: unset;
  }

  ::v-deep .dropdown {
    &-trigger {
      &::after {
        border: 0;
        background: #5f5f5f; // 吹き出しの矢印部分をなじませるため色を直指定
      }
    }
  }

  ::v-deep .dropdown-menu.dropdown-content {
    border: 0;
    background: rgba(51, 51, 51, 0.8);
    color: $color-white;
  }
}
</style>
