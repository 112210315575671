<template>
  <div class="c-contentsFreeText">
    <div
      class="c-contentsFreeText_text"
      v-html="content"
    ></div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'ContentsFreeText',
  props: {
    freeText: {
      type: String,
      defalut: ''
    }
  },
  data() {
    return {
      content: this.freeText
    }
  },
  fetch() {
    this.autoLink()
  },
  computed: {
    ...mapState('constants', ['COCONALA_WHITE_LIST_URL'])
  },
  methods: {
    autoLink() {
      const WHITE_LIST_URL_PATTERN = Object.values(this.COCONALA_WHITE_LIST_URL).join('|')
      const reg = new RegExp(`(${WHITE_LIST_URL_PATTERN})`, 'g')

      const content = this.$util.htmlspecialchars(this.freeText)
      this.content = content.replace(reg, match => {
        return `<a target="_blank" rel="noopener" href="${match}">${match}</a>`
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.c-contentsFreeText {
  &_text {
    height: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow: hidden;
  }
}
</style>
