<template>
  <div
    @click.stop.prevent
    :class="`c-favButtonType${type}`"
  >
    <ClientOnly>
      <c-modal
        v-if="isLoggedIn && foldersList && foldersList.length < MAX_FOLDERS_LENGTH"
        :title="$t('components.fav_button_v2.new_folder')"
        class="c-addFolderModal"
        :active="showAddFolderModal"
        @close="closeAddFolderModal"
      >
        <c-field
          :class="{ '-isLengthOver': addFolderName.length > MAX_FOLDER_NAME_LENGTH }"
          expanded
        >
          <c-input
            :maxlength="MAX_FOLDER_NAME_LENGTH"
            size="medium"
            expanded
            :placeholder="$t('components.fav_button_v2.new_folder_message')"
            v-model="addFolderName"
          />
        </c-field>
        <div class="c-addFolderModal_buttons">
          <c-button
            color="thirdly"
            size="medium"
            @click="closeAddFolderModal"
          >
            {{ $t('components.fav_button_v2.cancel') }}
          </c-button>
          <c-button
            color="primary-green"
            size="medium"
            :disabled="
              !addFolderName || addFolderName.length > MAX_FOLDER_NAME_LENGTH || isPostingNewFolder
            "
            @click="addFolder"
          >
            {{ $t('components.fav_button_v2.create') }}
          </c-button>
        </div>
      </c-modal>
    </ClientOnly>
    <template v-if="type === 'TextCount'">
      <a
        v-show="!isPC"
        href="javascript:;"
        slot="trigger"
        class="c-favButton c-favButtonTextCountSp"
        :class="{ 'c-favButton-isFavorited': isFavoritedLatest }"
        @click.prevent="onFavoriteButtonClicked"
      >
        <coconala-icon name="heart" />
        <span class="c-favButtonTextCountSp_num">
          {{ isFavoritedCount }}
        </span>
      </a>
    </template>
    <template v-else>
      <a
        v-show="!isPC"
        href="javascript:;"
        slot="trigger"
        class="c-favButton"
        :class="{ 'c-favButton-isFavorited': isFavoritedLatest }"
        @click.prevent="onFavoriteButtonClicked"
      >
        <coconala-icon name="heart-circle" />
      </a>
    </template>
    <ClientOnly>
      <ODropdown
        v-if="isPC"
        class="c-favButtonWrapper"
        :class="{
          'c-favButtonWrapper-isFavorited': isFavoritedLatest,
          'c-favButtonWrapper-isDropdownActive': isDropdownActive,
          'c-favButtonWrapper-isAlwaysShow': isAlwaysShow
        }"
        aria-role="list"
        ref="dropdown"
        @active-change="isActive => (isDropdownActive = isActive)"
      >
        <span
          slot="trigger"
          class="c-favButtonTextCount"
          :class="{ 'c-favButtonTextCount-isFavorited': isFavoritedLatest }"
          @click.prevent="onFavoriteButtonClicked"
          v-if="type === 'TextCount'"
        >
          <span class="c-favButtonTextCount_column c-favButtonTextCount_text">
            <coconala-icon name="heart" />
            {{ $t('components.fav_button_v2.message') }}
          </span>
          <span class="c-favButtonTextCount_column c-favButtonTextCount_num">
            {{ isFavoritedCount }}
          </span>
        </span>
        <span
          slot="trigger"
          class="c-favButton"
          :class="{ 'c-favButton-isFavorited': isFavoritedLatest }"
          @click.prevent="onFavoriteButtonClicked"
          v-else
        >
          <coconala-icon name="heart-circle" />
        </span>
        <div
          v-if="isLoggedIn"
          class="c-folders"
        >
          <DLoading
            :active="isLoading"
            :is-full-page="false"
          />
          <div v-if="foldersList">
            <!--素直に<ODropdownItem>を使うと行クリック時にdropodownが閉じてしまうし、
              custom属性を付加して使ったとしても閉じない代わりにクリックイベントが発火しないので、使わない-->
            <ul>
              <li
                v-for="folder in foldersList"
                :key="folder.id"
              >
                <span
                  class="c-folders_folder c-folders_row"
                  @click="toggleFavorite(folder.id, folder.isFavorited)"
                >
                  <coconala-icon
                    name="heart"
                    :class="{ '-isFavorited': folder.isFavorited }"
                  />
                  {{ folder.name }}
                </span>
              </li>
            </ul>
            <hr v-if="foldersList.length < MAX_FOLDERS_LENGTH" />
            <span
              class="c-folders_addFolder c-folders_row"
              v-if="foldersList.length < MAX_FOLDERS_LENGTH"
              @click="showAddFolderModal = true"
            >
              <coconala-icon name="plus-thin" />
              {{ $t('components.fav_button_v2.new_folder') }}
            </span>
          </div>
        </div>
      </ODropdown>
    </ClientOnly>
  </div>
</template>

<script>
import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'
import { commonMetaData } from '~/store/_helpers/common-helper'

import { FolderService } from '~/stub/apigateway/service/folder_pb_service'
import { GetMyFoldersRequest } from '~/stub/apigateway/service/folder_pb'
import { AddFolderRequest } from '~/stub/apigateway/service/folder_pb'

import { FavoriteService } from '~/stub/apigateway/service/favorite_pb_service'
import { AddFavoriteRequest } from '~/stub/apigateway/service/favorite_pb'
import { DeleteFavoriteRequest } from '~/stub/apigateway/service/favorite_pb'

import { mapActions, mapGetters } from 'vuex'

import formMixin from '~/mixins/form-mixin'
import DLoading from '~/components/atoms/DS2/DLoading'

const MAX_FOLDERS_LENGTH = 10
const MAX_FOLDER_NAME_LENGTH = 25

export default {
  name: 'FavButtonV2',
  mixins: [formMixin],
  components: { DLoading },
  props: {
    isFavorited: {
      type: Boolean,
      default: false
    },
    favCount: {
      type: Number,
      default: 0
    },
    serviceId: {
      type: Number
    },
    isAlwaysShow: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'button'
    }
  },
  data() {
    return {
      foldersList: null,
      isDropdownActive: false,
      showAddFolderModal: false,
      MAX_FOLDERS_LENGTH,
      MAX_FOLDER_NAME_LENGTH,
      addFolderName: '',
      isLoading: false,
      isPostingNewFolder: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('ui', ['isPC']),
    isFavoritedLatest() {
      return this.foldersList ? this.foldersList.some(f => f.isFavorited) : this.isFavorited
    },
    isFavoritedCount() {
      if (!this.isFavorited && this.isFavoritedLatest) {
        return Number(this.favCount) + 1
      } else if (this.isFavorited && !this.isFavoritedLatest) {
        return Number(this.favCount) - 1
      }
      return Number(this.favCount)
    }
  },
  methods: {
    ...mapActions('ui', ['showLoginModal']),
    async onFavoriteButtonClicked(e) {
      if (!this.isLoggedIn) {
        e.preventDefault()
        this.showLoginModal()
        return
      }
      this.isLoading = true
      try {
        this.foldersList = await this.grpcGetMyFolders()
        if (this.$translate.isTranslatableTarget()) {
          this.translateDefaultFolder()
        }
        // エラー時はloadingのまま(catch節ではfalseにしない)
        this.isLoading = false
        if (!this.isPC) {
          this.toggleDefaultFavorite()
        }
      } catch (err) {
        this.notifyError('お気に入りフォルダ一覧の取得に失敗しました')
      }
    },
    async toggleFavorite(folderId, isFavorited) {
      try {
        await this.grpcToggleFavorite(folderId, isFavorited)
        this.foldersList = this.foldersList.map(f => {
          if (f.id === folderId) {
            f.isFavorited = !f.isFavorited
          }
          return f
        })
      } catch (err) {
        this.notifyFavoriteError(isFavorited)
      }
    },
    async toggleDefaultFavorite() {
      // デフォルトフォルダに限らずフォルダが1つでもお気に入りされていれば、toggleはお気に入り解除モード
      const isFavorited = this.isFavoritedLatest
      try {
        await this.grpcToggleFavorite(null, isFavorited)
        const defaultFolderId = this.foldersList.find(f => f.defaultFlag).id
        this.foldersList = this.foldersList.map(f => {
          // PC以外でのお気に入り解除操作は全フォルダのお気に入りを解除する
          if (isFavorited) {
            f.isFavorited = false
            return f
          } else {
            // お気に入り登録時はデフォルトフォルダのみが対象
            if (f.id === defaultFolderId) {
              f.isFavorited = true
            }
            return f
          }
        })
      } catch (err) {
        this.notifyFavoriteError(isFavorited)
      }
    },
    async addFolder() {
      try {
        this.isPostingNewFolder = true
        await this.grpcAddFolder(this.addFolderName)
        this.foldersList = await this.grpcGetMyFolders()
        this.closeAddFolderModal()
        this.$refs.dropdown.toggle()
        this.doNotifySuccess('お気に入りフォルダを作成しました')
      } catch (errorMessage) {
        this.notifyError(errorMessage || 'お気に入りフォルダの作成に失敗しました')
      }
      this.isPostingNewFolder = false
    },
    closeAddFolderModal() {
      this.addFolderName = ''
      this.showAddFolderModal = false
    },
    notifyError(message) {
      this.$notify({
        group: 'flash',
        title: message,
        type: 'error'
      })
    },
    notifyFavoriteError(isFavorited) {
      const message = `お気に入りの${isFavorited ? '解除' : '追加'}に失敗しました`
      this.notifyError(message)
    },
    grpcGetMyFolders() {
      return new Promise((resolve, reject) => {
        const request = new GetMyFoldersRequest()
        request.setServiceId(this.serviceId)
        const metadata = commonMetaData(this.$store.state)
        grpc.invoke(FolderService.GetMyFolders, {
          request,
          metadata,
          host: process.env.config.grpcWebUrl,
          transport: NodeHttpTransport(),
          onMessage: message => {
            const response = message.toObject()
            if (!response || !response.foldersList) {
              reject()
            }
            resolve(response.foldersList)
          },
          onEnd: (code, msg, trailers) => {
            if (code !== grpc.Code.OK) {
              reject()
            }
          }
        })
      })
    },
    grpcAddFolder(folderName) {
      return new Promise((resolve, reject) => {
        const request = new AddFolderRequest()
        request.setName(folderName)
        request.setServiceId(this.serviceId)
        const metadata = commonMetaData(this.$store.state)
        grpc.invoke(FolderService.AddFolder, {
          request,
          metadata,
          host: process.env.config.grpcWebUrl,
          transport: NodeHttpTransport(),
          onEnd: (code, msg, trailers) => {
            if (code === grpc.Code.OK) {
              resolve()
              return
            }
            if (code === grpc.Code.InvalidArgument) {
              const decodedMessage = this.$util.getGrpcErrorMessage(trailers, process.client)
              // 名前重複など
              reject(decodedMessage)
            }
            reject()
          }
        })
      })
    },
    grpcToggleFavorite(folderId, isFavorited) {
      return new Promise((resolve, reject) => {
        const request = isFavorited ? new DeleteFavoriteRequest() : new AddFavoriteRequest()
        request.setServiceId(this.serviceId)
        if (folderId) {
          request.setFavoriteFolderId(folderId)
        }
        const metadata = commonMetaData(this.$store.state)
        const targetRpc = isFavorited ? FavoriteService.DeleteFavorite : FavoriteService.AddFavorite
        grpc.invoke(targetRpc, {
          request,
          metadata,
          host: process.env.config.grpcWebUrl,
          transport: NodeHttpTransport(),
          onEnd: (code, msg, trailers) => {
            if (code !== grpc.Code.OK) {
              reject()
            }
            resolve()
          }
        })
      })
    },
    translateDefaultFolder() {
      this.foldersList.forEach(folder => {
        if (folder.defaultFlag) folder.name = this.$t('components.fav_button_v2.message')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-addFolderModal {
  ::v-deep .modal-content {
    width: 380px;
    cursor: default;

    .field {
      &.-isLengthOver {
        .counter {
          color: $color-red-500;
        }
      }
    }

    .input {
      height: 60px;
      font-size: 16px;
    }
  }

  &_buttons {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    ::v-deep .button {
      &.is-medium {
        width: 120px;
        @include link-effect;

        & + .is-medium {
          margin-left: 20px;
        }

        span {
          font-size: 13px;
        }
      }
    }
  }
}

.c-favButtonWrapper {
  display: none;

  &-isFavorited,
  // dropdownがactiveかつhoverが外れたとき、dropdownが非表示にならないように
  &-isDropdownActive,
  &-isAlwaysShow {
    display: block;
  }
}

.c-favButton {
  @include link-effect;
  display: block;
  width: 30px;
  height: 30px;
  color: $ds2-color-gray-200;
  position: relative;
  z-index: z(dropdown, content);

  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 8px;
    left: 5px;
    background-color: $color-white;
  }

  &-isFavorited {
    color: $color-pink-500;
  }

  i {
    font-size: 30px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media (max-width: breakpoint(Search, M)) {
    width: 28px;
    height: 28px;

    &::after {
      width: 22px;
      height: 22px;
      top: 5px;
    }

    i {
      font-size: 28px;
    }
  }
}

.c-favButtonTextCount {
  @include link-effect;
  display: flex;
  justify-content: space-between;
  padding: 4px 8px;
  width: 140px;
  height: 30px;
  color: $ds2-color-gray-900;
  position: relative;
  z-index: 20;
  border-radius: 3px;
  border: 1px solid $ds2-color-gray-200;
  font-size: 12px;
  cursor: pointer;

  i {
    font-size: 16px;
    color: $ds2-color-gray-200;
    position: relative;
    margin-right: 4px;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &-isFavorited {
    i {
      color: $color-pink-500;
    }
  }

  &_column {
    align-self: center;
    line-height: 20px;
  }
}

::v-deep .dropdown-menu {
  left: -163px;
  top: -9px;
  width: 150px;
  min-width: unset;
  z-index: 21;
  cursor: default;

  // 三角形
  &::before {
    content: '';
    position: absolute;
    right: -11px;
    top: 20px;
    border-style: solid;
    border-color: $color-white transparent transparent;
    border-width: 8px 8px 0;
    transform: rotate(270deg);
  }
}

.c-favButtonTypeTextCount {
  ::v-deep .dropdown-menu {
    left: auto;
    top: 38px;
    right: 0;
    border-radius: 6px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);

    // 三角形
    &::before {
      position: absolute;
      content: '';
      width: 10px;
      height: 10px;
      top: -5px;
      left: 50%;
      right: auto;
      border: 0;
      background-color: $color-white;
      box-shadow: 0 -4px 4px -2px rgba(0, 0, 0, 0.3);
      transform: translateX(-50%) rotate(45deg);
      z-index: 1;
    }

    .dropdown-content {
      z-index: 3;
      position: relative;
      box-shadow: none;
    }
  }
}

.c-folders {
  min-height: 32px;

  ::v-deep .loading-background {
    background: unset;
  }

  ::v-deep .loading-icon {
    &::after {
      top: -11px;
      left: -12px;
      width: 2em;
      height: 2em;
    }
  }

  &_row {
    padding: 0 8px;
    font-size: 11px;
    display: block;
    line-height: 21px;
    cursor: pointer;

    &:hover {
      // 本来Orugaのdropdowm-item:hoverで使っている背景色
      background-color: whitesmoke;
    }
  }

  &_folder {
    color: $ds2-color-font-base;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    i {
      color: $ds2-color-gray-200;
      &.-isFavorited {
        color: $color-pink-500;
      }
    }
  }

  hr {
    margin: 4px 8px;
    background-color: $ds2-color-gray-50;
    height: 1px;
  }

  &_addFolder {
    color: $ds2-color-brand-secondary-700;
  }
}

.c-favButtonTextCountSp {
  @include link-effect;
  width: 100%;
  height: 32px;
  background-color: $color-white;
  color: $ds2-color-gray-900;
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  padding: 0 13px;
  font-size: 13px;
  line-height: 30px;

  -webkit-appearance: none;

  i {
    font-size: 16px;
    color: $ds2-color-gray-200;
    position: relative;
    margin-right: 4px;
    top: 0;
    left: 0;
    z-index: 1;
  }

  &.c-favButton-isFavorited {
    i {
      color: $color-pink-500;
    }
  }

  &_num {
    font-weight: bold;
  }
}
</style>
