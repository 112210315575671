<template>
  <div
    class="d-selectCustomizeTrigger"
    :style="rootStyle"
  >
    <div
      class="d-selectCustomizeTrigger_triggerArea"
      ref="trigger"
    >
      <slot name="trigger" />
    </div>
    <div class="d-selectCustomizeTrigger_selectArea">
      <select
        :style="rootStyle"
        v-model="innerValue"
      >
        <slot name="option" />
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DSelectCustomizeTrigger',
  props: {
    value: [String, Number]
  },
  data() {
    return {
      rootStyle: {}
    }
  },
  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    value() {
      this.$nextTick().then(() => this.setRootStyle())
    }
  },
  methods: {
    setRootStyle: function () {
      this.rootStyle = {
        width: `${this.$refs.trigger?.clientWidth || 0}px`,
        height: `${this.$refs.trigger?.clientHeight || 0}px`
      }
    }
  },
  mounted() {
    this.setRootStyle()
  }
}
</script>

<style lang="scss" scoped>
.d-selectCustomizeTrigger {
  @include link-effect;

  position: relative;
  &_selectArea {
    position: absolute;
    opacity: 0;
    select {
      cursor: pointer;
    }
  }
  &_triggerArea {
    position: absolute;
    word-break: keep-all;
  }
}
</style>
