export default {
  methods: {
    getMetaImage(serviceDetail, type = '') {
      let width = 1220
      let height = 1240
      let imageType = 'resize'
      let isOgp = false
      let youtubeThumbnailSize = 'max'

      switch (type) {
        case 'ogp':
          isOgp = true
          break

        case 'reservation':
          width = 112
          height = 92
          imageType = 'crop'
          youtubeThumbnailSize = 'default'
          break

        default:
          break
      }

      const moviePriorityFlag = serviceDetail.moviePriorityFlag
      const serviceImagesList = serviceDetail.serviceImagesList

      if (moviePriorityFlag || serviceImagesList.length === 0) {
        const serviceMoviesList = serviceDetail.serviceMoviesList
        if (serviceMoviesList.length) {
          return this.$util.getYoutubeThumbnailURL(
            serviceMoviesList[0].providerSiteMovieId,
            youtubeThumbnailSize
          )
        }

        if (serviceImagesList.length) {
          return this.$coconala.serviceImageURLV2(serviceImagesList[0], width, height, imageType)
        }

        return isOgp
          ? 'https://coconala.com/images/facebook.png'
          : 'https://coconala.com/images/no_image_item.png'
      }

      return this.$coconala.serviceImageURLV2(serviceImagesList[0], width, height, imageType)
    }
  }
}
