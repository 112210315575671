// source: domain/enumeration/provider_rank.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.coconala.protobuf.domain.enumeration.ProviderRank', null, global);
/**
 * @enum {number}
 */
proto.coconala.protobuf.domain.enumeration.ProviderRank = {
  PROVIDER_RANK_NO_RANK: 0,
  PROVIDER_RANK_REGULAR: 1,
  PROVIDER_RANK_BRONZE: 2,
  PROVIDER_RANK_SILVER: 3,
  PROVIDER_RANK_GOLD: 4,
  PROVIDER_RANK_PLATINUM: 5
};

goog.object.extend(exports, proto.coconala.protobuf.domain.enumeration);
