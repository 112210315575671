<template>
  <div
    class="c-serviceDetailProvider"
    v-intersect-translate.once
  >
    <a
      :href="'/users/' + user.id"
      target="_blank"
      rel="noopener"
      class="c-serviceDetailProvider_link"
    >
      <h2 class="c-heading">
        {{ $t('components.service_detail_provider.profile') }}
      </h2>
      <div class="c-provider">
        <div class="c-providerIcon c-provider_icon">
          <div class="c-providerIcon_inner">
            <c-user-icon-v2
              :file-path="user.resizableProfileIcon"
              :user-name="user.name"
              :size="90"
              v-if="isPC"
            />
            <c-user-icon-v2
              :file-path="user.resizableProfileIcon"
              :user-name="user.name"
              :size="56"
              v-else-if="isSP || isTB"
            />
            <div
              class="c-providerIcon_level c-levelIcon"
              v-if="isPC && user.level && user.level.value"
            >
              <balloon-dropdown
                position="bottom-right"
                :triggers="['hover']"
                width="300px"
              >
                <a
                  href="javascript:;"
                  slot="trigger"
                >
                  <img
                    :src="$coconala.levelIconURL(user.level.value)"
                    alt=""
                    class="c-levelIcon_level"
                  />
                </a>
                <div
                  class="c-levelIcon_help"
                  @click.stop.prevent
                >
                  <div class="c-levelIcon_helpTitle">
                    <strong>
                      {{ $t('components.service_detail_provider.rank') }}{{ user.level.nameText }}
                    </strong>
                  </div>
                  <div class="c-levelIcon_helpText">
                    <div v-html="generateLevelHelpText(user.level.value)"></div>
                  </div>
                  <div class="c-levelIcon_helpImage">
                    <img
                      src="@/assets/images/common/level-balloon.png"
                      class="popover-rank-image"
                      width="261"
                    />
                  </div>
                  <div class="c-levelIcon_helpLink">
                    <a
                      href="javascript:;"
                      @click="openLinkBlank('/pages/about_rank')"
                    >
                      {{ $t('components.service_detail_provider.detail_link') }}
                      <coconala-icon name="chevron-right" />
                    </a>
                  </div>
                </div>
              </balloon-dropdown>
            </div>
          </div>
          <div class="c-providerInfo c-provider_info">
            <div
              class="c-providerInfo_name c-providerName"
              data-ignore-translate
            >
              {{ user.name }}
            </div>
            <div
              v-if="!isForeigners"
              class="c-providerInfo_profileLink c-profileLink"
            >
              {{ $t('components.service_detail_provider.profile_detail') }}
              <coconala-icon
                name="chevron-right"
                size="11px"
              />
            </div>
            <div class="c-providerInfo_lastLogin c-lastLogin">
              <div
                class="c-lastLoginRow"
                v-if="user.sex"
              >
                <div class="c-lastLoginRow_content">
                  {{ sexes[user.sex] }}
                </div>
              </div>
              <div class="c-lastLoginRow">
                <div class="c-lastLoginRow_label">
                  {{ $t('components.service_detail_provider.last_login') }}
                </div>
                <div class="c-lastLoginRow_content">
                  {{ $util.prettyDate(user.lastLoginedAt) }}
                </div>
              </div>
            </div>
            <div
              class="c-providerInfo_achievementCount c-achievementCount"
              v-if="user.achievementCount"
            >
              {{ $t('components.service_detail_provider.total_sales') }}
              {{ $util.numberWithDelimiter(user.achievementCount) }} {{ $t('cases') }}
            </div>
            <div
              class="c-providerInfo_levelSp c-levelSp"
              v-if="isSP || isTB"
            >
              <div
                class="c-levelSp_level"
                v-if="user.level && user.level.nameText"
              >
                <img
                  :src="$coconala.levelIconURL(user.level.value)"
                  alt=""
                  class="c-levelSp_levelIcon"
                  v-if="user.level && user.level.value"
                />
                <span>{{ user.level.nameText }}</span>
              </div>
              <div
                class="c-levelSp_achievementCount"
                v-if="user.achievementCount"
              >
                {{ $t('components.service_detail_provider.total_sales')
                }}{{ $util.numberWithDelimiter(user.achievementCount) }} {{ $t('cases') }}
              </div>
            </div>
            <div class="c-providerInfo_identification c-identification">
              <div class="c-identification_identification">
                <coconala-icon
                  name="user-square"
                  class="c-identification_iconIdentification"
                />
                <div class="c-identification_label">
                  {{ $t('components.service_detail_provider.identification') }}
                </div>
                <coconala-icon
                  :name="user.identificationFg ? 'check' : 'minus'"
                  :class="[
                    user.identificationFg
                      ? 'c-identification_iconCheck'
                      : 'c-identification_iconMinus'
                  ]"
                />
              </div>
              <div class="c-identification_nda">
                <coconala-icon
                  name="lock_square"
                  class="c-identification_iconNda"
                />
                <div class="c-identification_label">
                  {{ $t('components.service_detail_provider.nda') }}
                </div>
                <coconala-icon
                  :name="user.ndaConclusionFg ? 'check' : 'minus'"
                  :class="[
                    user.ndaConclusionFg
                      ? 'c-identification_iconCheck'
                      : 'c-identification_iconMinus'
                  ]"
                />
              </div>
              <div class="c-identification_invoice">
                <coconala-icon
                  name="document-square"
                  class="c-identification_iconIdentification"
                />
                <div class="c-identification_label">インボイス発行事業者</div>
                <coconala-icon
                  :name="user.isQualifiedInvoiceIssuer ? 'check' : 'minus'"
                  :class="[
                    user.isQualifiedInvoiceIssuer
                      ? 'c-identification_iconCheck'
                      : 'c-identification_iconMinus'
                  ]"
                />
              </div>
            </div>
          </div>

          <div
            class="c-providerArrow c-provider_arrow"
            v-if="isSP || isTB"
          >
            <coconala-icon
              name="chevron-right"
              class="c-providerArrow_icon"
            />
          </div>
        </div>
      </div>
    </a>
    <div
      class="c-additionalData"
      :class="{ 'c-additionalData_noItem': !isAdditionalData }"
      v-show="isPC"
    >
      <div
        class="c-buttons"
        v-if="!isMyService"
      >
        <div class="c-buttons_dm">
          <c-button-v2
            color="thirdly"
            size="s"
            width="100%"
            :href="urlDm"
            need-logged-in
          >
            <span class="c-buttonsDmInner">
              <span class="c-buttonsDmInner_center">
                <coconala-icon
                  name="envelope-o"
                  class="c-buttons_dmIcon"
                  size="16px"
                />
                <span class="c-buttons_dmText">
                  {{ $t('components.service_detail_provider.ask_the_exhibitor') }}
                </span>
              </span>
            </span>
          </c-button-v2>
        </div>
        <div class="c-buttons_followWrapper">
          <div class="c-buttons_follow">
            <c-button-v2
              color="thirdly"
              size="s"
              width="100%"
              @click="toggleFollow"
              @mouseover="
                checkStatusAndOpenBalloon({
                  isFollowed: user.userRelation.followingFlag,
                  providerId: user.id
                })
              "
            >
              <!-- フォロワーカウントが四桁の時はpaddingなし -->
              <span
                class="c-buttonsFollowInner"
                :class="user.followerCount > 999 ? 'c-buttonsFollowInner-noPadding' : ''"
              >
                <span class="c-buttonsFollowInner_left">
                  <coconala-icon
                    :name="!user.userRelation.followingFlag ? 'user-plus' : 'user-check'"
                    class="c-buttons_followIcon"
                    :class="{ 'c-buttons_followIcon-following': user.userRelation.followingFlag }"
                    size="16px"
                  />
                  <span class="c-buttons_followText">
                    {{
                      user.userRelation.followingFlag
                        ? $t('components.service_detail_provider.unfollow')
                        : $t('components.service_detail_provider.follow')
                    }}
                  </span>
                </span>
                <span class="c-buttonsFollowInner_right">
                  <span>{{ user.followerCount }}</span>
                </span>
              </span>
            </c-button-v2>
          </div>
          <ClientOnly>
            <DCouponNotificationBalloon
              v-if="isShowCouponNotificationBalloon"
              :provider-id="user.id"
              :is-show-coupon-notification-balloon="isShowCouponNotificationBalloon"
              @close="isShowCouponNotificationBalloon = false"
              @finish-optin-following="getFollowupReplyStatusResponse(user.id)"
            />
          </ClientOnly>
        </div>
      </div>

      <div
        class="c-message"
        v-if="user.message"
      >
        <ProviderCollapse
          class="c-spContents c-messageFreeText"
          :free-text="user.message"
          expansion
        />
      </div>

      <hr
        class="c-divider"
        v-if="isAdditionalData"
      />

      <div
        class="c-additionalDataItem c-schedule"
        v-if="user.schedule"
      >
        <div class="c-additionalDataHeading">
          <div class="c-additionalDataHeading_inner">
            {{ $t('components.service_detail_provider.schedule') }}
          </div>
        </div>
        <ProviderCollapse
          class="c-spContents c-messageFreeText"
          :free-text="user.schedule"
          expansion
        />
      </div>

      <div
        class="c-additionalDataItem c-specialty"
        v-if="user.userSpecialtiesList.length"
      >
        <div class="c-additionalDataHeading">
          <div class="c-additionalDataHeading_inner">
            {{ $t('components.service_detail_provider.area_of_specialty') }}
          </div>
        </div>
        <ul>
          <li
            v-for="(specialty, index) in user.userSpecialtiesList"
            :key="`specialty${index}`"
            class="c-specialty_item"
          >
            <div class="c-specialtyHeading">
              <coconala-icon
                name="square"
                size="10px"
                class="c-specialtyHeading_icon"
              />
              {{ getCategoryName(specialty.masterCategoryId) }}
            </div>
            <div class="c-specialtyDetails">
              <ul>
                <li
                  v-for="(specialtyDetail, index) in specialty.userSpecialtyDetailsList"
                  :key="`specialtyDetail${index}`"
                  class="c-specialtyDetails_item"
                >
                  {{ specialtyDetail.specialtyName }}
                </li>
              </ul>
            </div>
            <div class="c-specialtyTags">
              <ul class="c-specialtyTags_list">
                <li
                  v-for="(specialtyTag, index) in specialty.tagsList"
                  :key="`specialtyTag${index}`"
                  class="c-specialtyTags_item"
                >
                  {{ specialtyTag }}
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>

      <div
        v-if="skillsList.length"
        class="c-additionalDataItem c-skill"
      >
        <div class="c-additionalDataHeading">
          <div class="c-additionalDataHeading_inner">
            {{ $t('components.service_detail_provider.skills_and_tools') }}
          </div>
        </div>
        <div class="c-skills">
          <ul>
            <li
              v-for="(skill, index) in skillsList"
              :key="`skill${index}`"
              class="c-skills_item"
            >
              {{ skill.name }}：{{
                skill.yearsOfExperience === 0
                  ? $t('components.service_detail_provider.less_than_one_year')
                  : `${skill.yearsOfExperience}${$t('year')}`
              }}
            </li>
          </ul>
        </div>
      </div>
      <div
        v-else-if="skillsListUnspecified.length"
        class="c-additionalDataItem c-skill"
      >
        <div class="c-additionalDataHeading">
          <div class="c-additionalDataHeading_inner">
            {{ $t('components.service_detail_provider.skills_and_tools_old') }}
          </div>
        </div>
        <div class="c-skills">
          <ul>
            <li
              v-for="(skill, index) in skillsListUnspecified"
              :key="`skill${index}`"
              class="c-skills_item"
            >
              {{ skill.name }}：{{ skill.yearsOfExperience }}{{ $t('year') }}
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="licensesList.length"
        class="c-additionalDataItem c-license"
      >
        <div class="c-additionalDataHeading">
          <div class="c-additionalDataHeading_inner">
            {{ $t('components.service_detail_provider.qualifications') }}
          </div>
        </div>
        <div class="c-licenses">
          <ul>
            <li
              v-for="(license, index) in licensesList"
              :key="`license${index}`"
              class="c-licenses_item"
            >
              {{ license.name }}
            </li>
          </ul>
        </div>
      </div>

      <div
        class="c-additionalDataItem c-award"
        v-if="user.awardsList.length"
      >
        <div class="c-additionalDataHeading">
          <div class="c-additionalDataHeading_inner">
            {{ $t('components.service_detail_provider.awards') }}
          </div>
        </div>
        <div class="c-awards">
          <ul>
            <li
              v-for="(award, index) in user.awardsList"
              :key="`award${index}`"
              class="c-awards_item"
            >
              {{ award.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="c-scheduleSp"
      v-if="user.schedule"
    >
      <div class="c-scheduleSp_heading">
        <div class="c-scheduleSp_headingInner">
          {{ $t('components.service_detail_provider.schedule') }}
        </div>
      </div>
      <ScheduleSpCollapse
        class="c-scheduleSp_contents"
        :free-text="user.schedule"
        expansion
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import BalloonDropdown from '~/components/molecules/BalloonDropdown'

// mixins
import formMixin from '~/mixins/form-mixin'
import providerCouponMixin from '~/mixins/provider-coupon-mixin'

import { ProviderCouponFollowupReplyStatus } from '~/stub/domain/enumeration/provider_coupon_followup_reply_status_pb'
import { SkillKind } from '~/stub/profileservice/skill_pb'

// 出品者クーポンの受け取り状況
const { PROVIDER_COUPON_FOLLOWUP_REPLY_STATUS_UNSPECIFIED } = ProviderCouponFollowupReplyStatus

export default {
  name: 'ServiceDetailProvider',
  mixins: [formMixin, providerCouponMixin],
  components: {
    BalloonDropdown,
    ProviderCollapse: () => import('./components/ProviderCollapse'),
    ScheduleSpCollapse: () => import('./components/ScheduleSpCollapse'),
    DCouponNotificationBalloon: () =>
      import('~/components/organisms/DS2/DCouponNotificationBalloon')
  },
  props: {
    serviceDetail: {
      type: Object,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    urlDm: {
      type: String,
      required: true
    },
    isMyService: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShowCouponNotificationBalloon: false,
      isForeigners: false,
      providerCouponFollowupReplyStatus: PROVIDER_COUPON_FOLLOWUP_REPLY_STATUS_UNSPECIFIED
    }
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('constants', ['serviceClass']),
    ...mapState('master', ['masterCategories', 'sexes']),
    ...mapGetters('ui', ['isPC', 'isTB', 'isSP']),
    isAdditionalData() {
      return (
        this.user.schedule ||
        this.user.userSpecialtiesList.length ||
        this.user.skillsList.length ||
        this.user.licensesList.length ||
        this.user.awardsList.length
      )
    },
    skillsList() {
      // プロフィール情報強化PJ後のデータ
      return this.user.skillsList.filter(skill => skill.kind !== SkillKind.SKILL_KIND_UNSPECIFIED)
    },
    skillsListUnspecified() {
      // プロフィール情報強化PJ前のデータ
      return this.user.skillsList.filter(skill => skill.kind === SkillKind.SKILL_KIND_UNSPECIFIED)
    },
    licensesList() {
      const licensesList = this.user.licensesList.filter(license => !!license.licenseId)
      if (licensesList.length) {
        // プロフィール情報強化PJ後のデータがある場合
        return licensesList
      }

      return this.user.licensesList.filter(license => !license.licenseId)
    }
  },
  created() {
    this.isForeigners = this.$translate.isTranslatableTarget()
  },
  methods: {
    ...mapActions('ui', ['showLoginModal']),
    ...mapActions('pages/users/user', ['addFollow', 'deleteFollow']),
    getCategoryName(masterCategoryId) {
      return this.masterCategories.find(c => c.id === masterCategoryId)?.name
    },
    generateLevelHelpText(level) {
      if (level === 1) {
        return '<p>累積の販売実績が<strong>1件</strong>以上の出品者です。</p>'
      } else if (level === 2) {
        return '<p>累積の販売実績が<strong>10件</strong>以上あり、納品完了率<strong>80％</strong>以上の基準をクリアした出品者です。</p>'
      } else if (level === 3) {
        return '<p>直近3ヶ月間で一定の販売実績があり、納品完了率<strong>85%</strong>以上の基準をクリアした出品者です。</p>'
      } else if (level === 4) {
        return '<p>直近3ヶ月間で一定の販売実績があり、納品完了率<strong>90%</strong>以上、評価<strong>4.8</strong>以上の基準をクリアし、本人確認済みの信頼性の高い出品者です。</p>'
      } else if (level === 5) {
        return '<p>直近3ヶ月間で一定の販売実績があり、納品完了率<strong>90%</strong>以上、評価<strong>4.8</strong>以上の基準をクリアし、本人確認済みの信頼性の高い出品者です。</p>'
      }
      return ''
    },
    openLinkBlank(path) {
      open(path, '_blank')
    },
    async toggleFollow() {
      if (!this.isLoggedIn) return this.showLoginModal()
      if (this.user.userRelation.followingFlag) {
        await this.deleteFollow()
        this.doNotifySuccess('フォローを解除しました')
      } else {
        await this.addFollow()
        this.showCouponNotificationBalloon()
        this.doNotifySuccess('フォローしました')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.c-serviceDetailProvider {
  margin-bottom: 20px;
  padding: 0;
  border: 1px solid $ds2-color-gray-50;
  border-radius: 4px;

  &_link {
    @include link-effect;

    display: block;
    margin-bottom: 8px;
    padding: 16px 20px 8px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &:hover {
      background: $color-bg-pale;
      opacity: 1;
    }
  }
}

.c-heading {
  color: $ds2-color-gray-900;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.c-provider {
  display: flex;
  flex-direction: column;
}

.c-providerIcon {
  margin: 16px 0 0;
  text-align: center;

  &_inner {
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    width: 90px;
    height: 90px;
  }

  &_level {
    position: absolute;
    right: -8px;
    bottom: -8px;
    width: 40px;
    height: 40px;
  }
}

.c-levelIcon {
  &_level {
    width: 40px;
    height: 40px;
  }

  &_help {
    padding: 8px 12px;
    font-size: 12px;
  }

  &_helpTitle {
    margin-bottom: 8px;
    padding-bottom: 8px;
    border-bottom: 1px solid $color-white;
  }

  &_helpText {
    text-align: left;
  }

  &_helpImage {
    margin-bottom: 16px;
    text-align: center;
  }

  &_helpLink {
    text-align: right;

    a {
      color: $color-white;
    }
  }

  ::v-deep .dropdown {
    &-trigger {
      &::after {
        border: 0;
        background: #5f5f5f; // 吹き出しの矢印部分をなじませるため色を直指定
      }
    }
  }

  ::v-deep .dropdown-menu.dropdown-content {
    left: -180px;
    border: 0;
    background: rgba(51, 51, 51, 0.8);
    color: $color-white;
    cursor: default;
  }
}

.c-providerInfo {
  color: $ds2-color-gray-600;
  font-size: 11px;
}

.c-providerName {
  margin-bottom: 2px;
  color: $ds2-color-brand-secondary-700;
  text-align: center;
  font-size: 13px;
}

.c-profileLink {
  margin-bottom: 8px;
  color: $ds2-color-brand-secondary-700;
  text-align: center;
  font-size: 11px;
}

.c-lastLogin {
  display: flex;
  flex-direction: column;
  text-align: center;

  justify-content: center;
}

.c-lastLoginRow {
  display: flex;

  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.c-achievementCount {
  display: flex;
  margin-top: 2px;
  text-align: center;

  justify-content: center;
}

.c-identification {
  display: flex;
  margin-top: 8px;
  text-align: center;

  flex-wrap: wrap;
  justify-content: center;

  &_identification {
    display: flex;
    margin-right: 14px;

    align-items: center;
  }

  &_nda {
    display: flex;

    align-items: center;
  }

  &_invoice {
    display: flex;
    margin: 2px 14px 0 0;

    align-items: center;
  }

  &_label {
    margin: 0 4px;
  }

  &_iconIdentification {
    color: $ds2-color-gray-600;
  }

  &_iconNda {
    color: $ds2-color-gray-600;
  }

  &_iconCheck {
    color: $ds2-color-brand-primary-600;
  }

  &_iconMunus {
    color: $ds2-color-gray-50;
  }
}

.c-additionalData {
  padding: 0 20px;

  &_noItem {
    padding-bottom: 16px;
  }
}

.c-buttons {
  display: flex;

  justify-content: space-between;

  &_dm {
    margin-right: 4px;
    width: 132px;

    ::v-deep .c-buttonV2 {
      &.is-s {
        padding-right: 4px;
        padding-left: 4px;
        font-weight: normal;
      }
    }
  }

  &_dmIcon {
    display: inline-block;
    color: $ds2-color-brand-primary;
    line-height: 1.2;
  }

  &_followWrapper {
    position: relative;
  }

  &_follow {
    width: 132px;

    ::v-deep .c-buttonV2 {
      &.is-s {
        padding-right: 4px;
        padding-left: 4px;
        font-weight: normal;
      }
    }
  }

  &_followIcon {
    display: inline-block;
    color: $ds2-color-brand-primary;
    line-height: 1.2;

    &-following {
      color: $ds2-color-gray-600;
    }
  }
}

.c-buttonsDmInner {
  display: flex;
  padding: 0 4px;

  align-items: center;
  justify-content: center;
}

.c-buttonsFollowInner {
  display: flex;
  padding: 0 4px;

  align-items: center;
  justify-content: space-between;
  &-noPadding {
    padding: 0;
  }
  &_right {
    text-align: right;
  }
}

.c-additionalDataHeading {
  margin-bottom: 8px;

  &_inner {
    display: inline-block;
    padding: 4px 8px;
    background: $ds2-color-gray-25;
    font-size: 12px;
    line-height: 12px;
  }
}

.c-divider {
  display: block;
  margin: 16px 0;
  height: 1px;
  background-color: $ds2-color-gray-50;
}

.c-message {
  margin-top: 12px;
}

.c-additionalDataItem {
  margin-bottom: 16px;
}

.c-specialty {
  font-size: 12px;

  &_item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.c-specialtyHeading {
  margin-bottom: 4px;
  font-size: 12px;

  &_icon {
    color: $ds2-color-gray-400;
    font-size: 10px;
  }
}

.c-specialtyDetails {
  margin-bottom: 4px;

  &_item {
    display: inline;

    &::after {
      content: '/';
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.c-specialtyTags {
  &_list {
    display: flex;

    flex-wrap: wrap;
  }
  &_item {
    margin: 0 0.5em 0.5em 0;
    padding: 2px 5px;
    border: 1px solid $ds2-color-border-base;
    border-radius: 4px;
    white-space: nowrap;
    font-size: 10px;
  }
}

.c-skill {
  font-size: 12px;
}

.c-skills {
  &_item {
    display: inline;

    &::after {
      content: '/';
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.c-license {
  font-size: 12px;
}

.c-licenses {
  &_item {
    display: inline;

    &::after {
      content: '/';
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.c-award {
  font-size: 12px;
}

.c-awards {
  &_item {
    display: inline;

    &::after {
      content: '/';
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

@media (min-width: breakpoint(ServiceDetail, M) + 1) {
  .c-scheduleSp {
    display: none;
  }
}

@media (max-width: breakpoint(ServiceDetail, M)) {
  .c-serviceDetailProvider {
    margin-top: 12px;
    margin-bottom: 0;
    padding: 0;
    border-right: none;
    border-left: none;
    border-radius: 0;

    &_link {
      @include link-effect;

      padding: 8px 12px 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  .c-heading {
    margin-bottom: 8px;
    text-align: left;
    font-size: 12px;
  }

  .c-providerIcon {
    display: flex;
    flex-direction: row;
    margin: 0;

    &_inner {
      position: relative;
      display: inline-block;
      margin-right: 12px;
      margin-bottom: 0;
      width: 56px;
      height: 56px;
      text-align: center;

      flex-basis: 56px;
    }

    &_level {
      position: absolute;
      right: -4px;
      bottom: -2px;
      width: 24px;
      height: 24px;
    }
  }

  .c-providerInfo {
    flex-grow: 1;
  }

  .c-providerName {
    margin-bottom: 0;
    color: $ds2-color-brand-secondary-700;
    text-align: left;
    font-size: 12px;
  }

  .c-profileLink {
    display: none;
  }

  .c-lastLogin {
    flex-direction: row;
    margin-bottom: 0;
    text-align: left;

    justify-content: flex-start;
  }

  .c-lastLoginRow {
    &:not(:last-child) {
      margin-right: 12px;
      margin-bottom: 0;
    }
  }

  .c-achievementCount {
    display: none;
  }

  .c-levelSp {
    display: flex;
    text-align: left;

    &_level {
      margin-right: 12px;
    }

    &_levelIcon {
      position: relative;
      top: -1px;
      width: 14px;
      vertical-align: middle;
    }
  }

  .c-identification {
    flex-wrap: wrap;
    justify-content: flex-start;

    &_invoice {
      margin-top: 0;
    }
  }

  .c-providerArrow {
    text-align: right;

    align-self: center;
    flex-basis: 20px;

    &_icon {
      color: $ds2-color-gray-100;
    }
  }

  .c-scheduleSp {
    position: relative;
    padding: 8px 12px;
    border-top: 1px solid $ds2-color-gray-50;
    font-size: 12px;

    &_headingInner {
      display: inline-block;
      padding: 4px 8px;
      background: $ds2-color-gray-25;
      line-height: 12px;
    }

    &_contents {
      margin-top: 4px;
    }
  }
}
</style>
